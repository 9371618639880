import { defaultI18n } from "../../constants/i18n";
import { useI18nContext } from "../../contexts/I18nContext";
import { SettingSections, useSettingsContext } from "../../contexts/SettingsContext";
import { useSubtitleStateContext } from "../../contexts/SubtitleStateContext";
import SettingMenuHeader from "../settings/SettingMenuHeader";
import SettingMenuChecked from "./SettingMenuChecked";

const settingsBgOpacity = [0, 0.25, 0.5, 0.75, 1];

const SettingSubtitleBackgroundOpacity = () => {
  const { i18n } = useI18nContext();
  const { mediaState, setMediaState } = useSubtitleStateContext();
  const { sections, activeSection } = useSettingsContext();
  return (
    <div
      ref={sections[SettingSections.subtitleBackgroundOpacity]}
      className={`embed-settings-section ${
        activeSection === SettingSections.subtitleBackgroundOpacity
          ? "embed-settings-translate-center"
          : "embed-settings-translate-right"
      }`}
    >
      <SettingMenuHeader
        title={i18n?.settingsBackgroundOpacity || defaultI18n.settingsBackgroundOpacity}
        backSectionName={SettingSections.subtitleCustomMethods}
      />
      <div className="embed-settings-py">
        {settingsBgOpacity.map((backgroundOpacity) => (
          <SettingMenuChecked
            key={backgroundOpacity}
            handleOnClick={() => {
              setMediaState((prevState) => ({
                ...prevState,
                subtitleSettings: {
                  ...prevState.subtitleSettings,
                  backgroundOpacity,
                },
              }));
            }}
            settingSection={SettingSections.subtitleCustomMethods}
            isActive={mediaState.subtitleSettings.backgroundOpacity === backgroundOpacity}
            value={`${backgroundOpacity * 100}%`}
          />
        ))}
      </div>
    </div>
  );
};

export default SettingSubtitleBackgroundOpacity;
