import React, { RefObject, useMemo, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { IKeyBoardShortcut, ISource, ISubtitle } from "../types";

export interface EmbedPlayerProps extends React.HTMLAttributes<HTMLVideoElement> {
  playerKey?: string;
  sources: ISource[];
  subtitles?: ISubtitle[];
  dimensions?: number | { width: number | string; height: number | string };
  seekDuration?: number;
  autoPlay?: boolean;
  keyboardShortcut?: boolean | IKeyBoardShortcut;
  playerRef?: RefObject<HTMLVideoElement>;
  poster?: string;
  startTime?: number;
  meta?: {
    title?: string;
    imdb_id?: string;
    year?: number;
  };
  topHeaderComponent?: React.ReactNode;
  heading: string;
  rightControlsComponent?: React.ReactNode;
}

interface GlobalStateProviderProps {
  children: React.ReactNode;
  value: Partial<EmbedPlayerProps>;
}

const defaultState: EmbedPlayerProps = {
  playerKey: "embed-player",
  sources: [],
  subtitles: [],
  dimensions: 16 / 9,
  seekDuration: 10,
  startTime: 0,
  keyboardShortcut: true,
  autoPlay: true,
  playerRef: null as unknown as RefObject<HTMLVideoElement>,
  poster: "",
  heading: "",
};

interface IGlobalStateContext extends EmbedPlayerProps {
  setGlobalState: React.Dispatch<React.SetStateAction<EmbedPlayerProps>>;
}

const GlobalStateContext = createContext<IGlobalStateContext>({
  ...defaultState,
  setGlobalState: () => {},
});

function GlobalStateProvider(props: GlobalStateProviderProps) {
  const [globalState, setGlobalState] = useState({ ...defaultState, ...props.value });
  const value = useMemo(
    () => ({ ...globalState, ...props.value, setGlobalState }),
    [globalState, props.value]
  );
  return <GlobalStateContext.Provider value={value}>{props.children}</GlobalStateContext.Provider>;
}

function useGlobalStateContext() {
  const context = useContextSelector(GlobalStateContext, (state) => state);
  if (typeof context === "undefined") {
    throw new Error("useGlobalStateContext must be used within a GlobalStateProvider");
  }
  return context;
}

export { GlobalStateProvider, useGlobalStateContext };
