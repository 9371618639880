import { useQuery } from "@tanstack/react-query";
import { ExtractFnReturnType, QueryConfig } from "libs/react-query";
import { IMediaSource, IParamsMediaSource, IServer } from "./types";
import { getMediaSourcesFlixhq } from "./flixhq";
import { getMediaSourcesSuperstream } from "./superstream";
import { getMediaSourcesVisioncine } from "./visioncine";
import { getMediaSourcesLoveflix } from "./loveflix";

export const emptyMediaSources: IMediaSource = { sources: [], subtitles: [] };

export const allServers: IServer[] = [
  { name: "Opção 1", note: "Legendado", get: getMediaSourcesSuperstream, isSuccess: true },
  { name: "Opção 2", note: "Legendado", get: getMediaSourcesFlixhq, isSuccess: true },
  // { name: "Remotestream", note: "", get: getMediaSourcesRemotestream, isSuccess: true }, disabled because cors https://fsa.remotestre.am/Movies/315162/315162.m3u8
  { name: "Opção 3", note: "Dublado", get: getMediaSourcesLoveflix, isSuccess: true },
  { name: "Opção 4", note: "Dublado", get: getMediaSourcesVisioncine, isSuccess: true },
  // { name: "Embed", note: "Player contain ads", get: () => emptyMediaSources, isSuccess: true }, temp disabled because player contain ads
];

export const getMediaSources = async (params: IParamsMediaSource) => {
  let servers: IServer[] = allServers.map((x) => Object.assign({}, x));
  let mediaSources: IMediaSource = emptyMediaSources;
  for (let provider of servers) {
    try {
      const response = await provider.get(params);
      if (response?.sources?.length === 0) {
        provider.isSuccess = false;
        throw new Error(`Not found ${params.title} on ${provider.name}`);
      } else {
        mediaSources = response;
        provider.isSuccess = true;
        return { mediaSources, servers, activeServer: provider.name };
      }
    } catch (err) {
      console.log("err: ", err);
      provider.isSuccess = false;
      continue;
    }
  }
  return { mediaSources, servers, activeServer: servers[0].name };
};

type QueryFnType = typeof getMediaSources;

type UseMediaSourcesOptions = {
  params: IParamsMediaSource;
  config?: QueryConfig<QueryFnType>;
};

export const useMediaSources = ({ params, config }: UseMediaSourcesOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["mediaSources", params],
    queryFn: () => getMediaSources(params),
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * (60 * 1000),
  });
};
