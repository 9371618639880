import React, { ButtonHTMLAttributes, memo, useRef } from "react";
import { defaultI18n } from "../../constants/i18n";
import { useGlobalStateContext } from "../../contexts/GlobalStateContext";
import { useI18nContext } from "../../contexts/I18nContext";
import IconForward from "../../components/Icons/IconForward";

interface ButtonForwardMemoProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  playerRef: React.RefObject<HTMLVideoElement> | undefined;
  seekDuration: number;
}

const ButtonForwardMemo = memo((props: ButtonForwardMemoProps) => {
  const { playerRef, seekDuration, ...others } = props;
  const { i18n } = useI18nContext();
  const forwardRef = useRef<HTMLButtonElement>(null);
  const handleForward = () => {
    if (!playerRef || !playerRef.current) return;
    playerRef.current.currentTime = playerRef.current.currentTime + (seekDuration || 10);
  };
  return (
    <button
      ref={forwardRef}
      className="embed-center-container embed-tooltips-left embed-scale"
      data-embed-tooltips={
        i18n?.forward(seekDuration || 10) || defaultI18n?.forward(seekDuration || 10)
      }
      onClickCapture={handleForward}
      {...others}
    >
      <IconForward className="embed-icon-lg" />
    </button>
  );
});

ButtonForwardMemo.displayName = "ButtonForwardMemo";

const ButtonForward = () => {
  const { playerRef, seekDuration } = useGlobalStateContext();
  return <ButtonForwardMemo playerRef={playerRef} seekDuration={seekDuration || 10} />;
};

export default ButtonForward;
