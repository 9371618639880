import { defaultI18n } from "../../constants/i18n";
import { defaultSubtitleSettings } from "../../constants/localStorage";
import { useI18nContext } from "../../contexts/I18nContext";
import { SettingSections, useSettingsContext } from "../../contexts/SettingsContext";
import { useSubtitleStateContext } from "../../contexts/SubtitleStateContext";
import SettingMenuHeader from "../settings/SettingMenuHeader";
import SettingMenuItem from "./SettingMenuItem";
import SettingSubtitleBackgroundOpacity from "./SettingSubtitleBackgroundOpacity";
import SettingSubtitleColor from "./SettingSubtitleColor";
import SettingSubtitleFontSize from "./SettingSubtitleFontSize";
import SettingSubtitleLatency from "./SettingSubtitleLatency";

const SettingSubtitleCustomMethods = () => {
  const { mediaState, setMediaState } = useSubtitleStateContext();
  const { i18n } = useI18nContext();
  const { sections, activeSection } = useSettingsContext();
  const handleResetSettingsSubtitle = () => {
    setMediaState((prevState) => ({
      ...prevState,
      subtitleSettings: defaultSubtitleSettings,
    }));
  };
  return (
    <>
      <div
        ref={sections[SettingSections.subtitleCustomMethods]}
        className={`embed-settings-section ${
          activeSection === SettingSections.subtitleCustomMethods
            ? "embed-settings-translate-center"
            : "embed-settings-translate-left"
        }`}
      >
        <SettingMenuHeader
          title={i18n?.settingsCustomSubtitles || defaultI18n.settingsCustomSubtitles}
          backSectionName={SettingSections.subtitles}
        />
        <div className="embed-settings-py">
          <SettingMenuItem
            settingSection={SettingSections.subtitleFontsize}
            title={i18n?.settingsFontSize || defaultI18n.settingsFontSize}
            value={`${Math.floor(mediaState.subtitleSettings.fontSize * 100)}%`}
          />
          <SettingMenuItem
            settingSection={SettingSections.subtitleBackgroundOpacity}
            title={i18n?.settingsBackgroundOpacity || defaultI18n.settingsBackgroundOpacity}
            value={`${mediaState.subtitleSettings.backgroundOpacity * 100}%`}
          />
          <SettingSubtitleColor />
          <SettingSubtitleLatency />
          <div onClick={handleResetSettingsSubtitle} className="embed-settings-item">
            <div className="embed-settings-item-left">
              <p>{i18n?.settingsReset || defaultI18n.settingsReset}</p>
            </div>
          </div>
        </div>
      </div>
      <SettingSubtitleFontSize />
      <SettingSubtitleBackgroundOpacity />
    </>
  );
};

export default SettingSubtitleCustomMethods;
