import { FC, useRef } from "react";
import { EmbedPlayerProps, GlobalStateProvider } from "./contexts/GlobalStateContext";
import { HoverStateProvider } from "./contexts/HoverStateContext";
import { I18nContextProvider } from "./contexts/I18nContext";
import { MediaStateProvider } from "./contexts/SubtitleStateContext";
import { VideoFewUpdatesContextProvider } from "./contexts/VideoFewUpdatesContext";
import { VideoLotsOfUpdatesProvider } from "./contexts/VideoLotsOfUpdatesContext";
import { VideoUpdateProvider } from "./contexts/VideoUpdateContext";
import ErrorBoundary from "./features/core/ErrorBoundary";
import Main from "./features/core/Main";

const EmbedPlayer: FC<EmbedPlayerProps> = (props) => {
  const myRef = useRef<HTMLVideoElement>(null);
  const newProps = { ...props, playerRef: props.playerRef || myRef };
  return (
    <GlobalStateProvider value={newProps}>
      <div className="fixed inset-0 embed">
        <VideoUpdateProvider>
          <VideoFewUpdatesContextProvider>
            <VideoLotsOfUpdatesProvider>
              <HoverStateProvider>
                <MediaStateProvider>
                  <I18nContextProvider>
                    <ErrorBoundary>
                      <Main />
                    </ErrorBoundary>
                  </I18nContextProvider>
                </MediaStateProvider>
              </HoverStateProvider>
            </VideoLotsOfUpdatesProvider>
          </VideoFewUpdatesContextProvider>
        </VideoUpdateProvider>
      </div>
    </GlobalStateProvider>
  );
};

// const EmbedPlayer = block<EmbedPlayerProps>(Player, { ssr: false });

export default EmbedPlayer;
export * from "./types";
