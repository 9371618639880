import React, {
  ButtonHTMLAttributes,
  Dispatch,
  RefObject,
  memo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useGlobalStateContext } from "../../contexts/GlobalStateContext";
import { useHoverStateContext } from "../../contexts/HoverStateContext";
import { useVideoFewUpdatesContext } from "../../contexts/VideoFewUpdatesContext";
import {
  ISeekPreview,
  IVideoUpdate,
  useVideoUpdateContext,
} from "../../contexts/VideoUpdateContext";
import { IKeyBoardShortcut } from "../../types";
import { formatVideoTime } from "../../utils/helper";
import VideoTimeIndicator from "./VideoTimeIndicator";
import { isMobile } from "react-device-detect";

interface VideoSeekBarMemoProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  duration: number;
  setVideoUpdate: Dispatch<React.SetStateAction<IVideoUpdate>>;
  seekPreview: ISeekPreview | null;
  currentTime: number;
  updateHoverState: () => void;
  playerRef: RefObject<HTMLVideoElement> | undefined;
  keyboardShortcut: boolean | IKeyBoardShortcut | undefined;
  seekDuration: number;
}

const VideoSeekBarMemo = memo((props: VideoSeekBarMemoProps) => {
  const {
    duration = 0,
    setVideoUpdate,
    updateHoverState,
    seekPreview,
    currentTime,
    playerRef,
    keyboardShortcut,
    seekDuration = 10,
  } = props;
  const seekRef = useRef<HTMLDivElement>(null);
  const mouseDownRef = useRef<Boolean>(false);
  const handleSeeking = useCallback(
    (offsetX: number) => {
      if (!playerRef?.current || !seekRef.current) return;
      const offset =
        (offsetX - seekRef.current.getBoundingClientRect().left) / seekRef.current.offsetWidth;
      const newTime =
        (Math.abs(offset) === Infinity || isNaN(offset) ? 0 : offset) *
        playerRef?.current?.duration;
      playerRef.current.currentTime = newTime;
      setVideoUpdate((prevState) => ({
        ...prevState,
        currentTime: newTime,
      }));
    },
    [playerRef, setVideoUpdate]
  );
  const handleSeekPreview = useCallback(
    (offsetX: number) => {
      if (!playerRef?.current || !seekRef.current) return;
      const left = seekRef.current.getBoundingClientRect().left;
      let offsetInPercentage = (offsetX - left) / seekRef.current.offsetWidth;
      offsetInPercentage =
        Math.abs(offsetInPercentage) === Infinity || isNaN(offsetInPercentage)
          ? 0
          : offsetInPercentage;
      let offsetInPixel = offsetInPercentage * seekRef.current.offsetWidth;
      let newTime = offsetInPercentage * playerRef?.current?.duration;
      if (isNaN(newTime)) {
        setVideoUpdate((prevState) => ({
          ...prevState,
          seekPreview: null,
        }));
      }
      if (newTime < 0) newTime = 0;
      setVideoUpdate((prevState) => ({
        ...prevState,
        seekPreview: {
          time: Math.round(newTime),
          offset: offsetInPixel,
        },
      }));
    },
    [playerRef, setVideoUpdate]
  );
  const listenMouseMoveSeeking = useCallback(() => {
    const moveHandler = (e: MouseEvent) => {
      handleSeekPreview(e.clientX);
      if (mouseDownRef.current) {
        handleSeeking(e.clientX);
      }
      updateHoverState();
    };
    const touchMoveHandler = (e: TouchEvent) => {
      handleSeekPreview(e.touches?.[0]?.pageX);
      if (mouseDownRef.current) {
        handleSeeking(e.touches?.[0]?.pageX);
      }
    };
    window.addEventListener("mousemove", moveHandler);
    window.addEventListener("touchmove", touchMoveHandler);
    window.addEventListener("mouseup", () => {
      window.removeEventListener("mousemove", moveHandler);
      mouseDownRef.current = false;
      setVideoUpdate((prevState) => ({
        ...prevState,
        seekPreview: null,
      }));
    });
    window.addEventListener("touchend", () => {
      window.removeEventListener("touchmove", touchMoveHandler);
      mouseDownRef.current = false;
      setVideoUpdate((prevState) => ({
        ...prevState,
        seekPreview: null,
      }));
    });
  }, [handleSeekPreview, handleSeeking, setVideoUpdate, updateHoverState]);
  useEffect(() => {
    const seekTime = (amount: number) => {
      playerRef?.current && (playerRef.current.currentTime += amount);
    };
    const keyHandler = (e: KeyboardEvent) => {
      if (!keyboardShortcut) return;
      if ((keyboardShortcut === true || keyboardShortcut.rewind) && e.key === "ArrowLeft") {
        seekTime(-seekDuration);
      }
      if ((keyboardShortcut === true || keyboardShortcut.forward) && e.key === "ArrowRight") {
        seekTime(seekDuration);
      }
    };
    window.addEventListener("keyup", keyHandler);
    return () => {
      window.removeEventListener("keyup", keyHandler);
    };
  }, [keyboardShortcut, playerRef, seekDuration]);
  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      mouseDownRef.current = true;
      handleSeeking(e.clientX);
      listenMouseMoveSeeking();
    },
    [handleSeeking, listenMouseMoveSeeking]
  );
  const handleTouchStart = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      mouseDownRef.current = true;
      handleSeeking(e.touches?.[0]?.pageX);
      listenMouseMoveSeeking();
    },
    [handleSeeking, listenMouseMoveSeeking]
  );
  const handleMouseLeave = useCallback(() => {
    setVideoUpdate((prevState) => ({
      ...prevState,
      seekPreview: null,
    }));
  }, [setVideoUpdate]);
  return (
    <div className="flex items-center gap-x-5">
      <div
        ref={seekRef}
        onMouseDown={(e) => handleMouseDown(e)}
        onTouchStart={(e) => handleTouchStart(e)}
        onMouseMove={(e) => handleSeekPreview(e.clientX)}
        onMouseLeave={handleMouseLeave}
        className="flex-1 embed-seek"
      >
        <div className="embed-seek-bar">
          <div
            style={{
              width: duration !== 0 ? `${Math.round((currentTime / duration) * 1000) / 10}%` : 0,
            }}
            className="embed-seek-left"
          ></div>
        </div>
        {seekPreview !== null && (
          <div
            className="embed-seek-preview"
            style={{
              left:
                seekPreview.offset < 16
                  ? 0
                  : seekPreview.offset > (seekRef.current?.offsetWidth || 0) - 16
                  ? "auto"
                  : seekPreview.offset,
              right: seekPreview.offset > (seekRef.current?.offsetWidth || 0) - 16 ? 0 : "auto",
              transform:
                seekPreview.offset < 16 ||
                seekPreview.offset > (seekRef.current?.offsetWidth || 0) - 16
                  ? "none"
                  : "translateX(-50%)",
            }}
          >
            {formatVideoTime(seekPreview.time)}
          </div>
        )}
      </div>
      {isMobile ? null : <VideoTimeIndicator />}
    </div>
  );
});

VideoSeekBarMemo.displayName = "VideoSeekBarMemo";

const VideoSeekBar = () => {
  const { videoFewUpdatesContext } = useVideoFewUpdatesContext();
  const { videoUpdate, setVideoUpdate } = useVideoUpdateContext();
  const { updateHoverState } = useHoverStateContext();
  const { playerRef, keyboardShortcut, seekDuration = 10 } = useGlobalStateContext();
  return (
    <VideoSeekBarMemo
      duration={videoFewUpdatesContext.duration}
      setVideoUpdate={setVideoUpdate}
      seekPreview={videoUpdate.seekPreview}
      currentTime={videoUpdate.currentTime}
      updateHoverState={updateHoverState}
      playerRef={playerRef}
      keyboardShortcut={keyboardShortcut}
      seekDuration={seekDuration}
    />
  );
};

export default VideoSeekBar;
