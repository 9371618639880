import { defaultI18n } from "../../constants/i18n";
import { useI18nContext } from "../../contexts/I18nContext";
import { SettingSections, useSettingsContext } from "../../contexts/SettingsContext";
import { useVideoFewUpdatesContext } from "../../contexts/VideoFewUpdatesContext";
import SettingMenuHeader from "../settings/SettingMenuHeader";
import SettingMenuChecked from "./SettingMenuChecked";

const speeds = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

const SettingSpeeds = () => {
  const { i18n } = useI18nContext();
  const { videoFewUpdatesContext, setVideoFewUpdatesContext } = useVideoFewUpdatesContext();
  const { sections, activeSection, setActiveSection } = useSettingsContext();
  return (
    <div
      ref={sections[SettingSections.speed]}
      className={`embed-settings-section ${
        activeSection === SettingSections.speed
          ? "embed-settings-translate-center"
          : "embed-settings-translate-right"
      }`}
    >
      <SettingMenuHeader
        title={i18n?.settingsPlaybackSpeed || defaultI18n.settingsPlaybackSpeed}
        backSectionName={SettingSections.main}
      />
      <div className="embed-settings-py">
        {speeds.map((item, index) => {
          return (
            <SettingMenuChecked
              key={item}
              handleOnClick={() => {
                setVideoFewUpdatesContext((prevState) => ({
                  ...prevState,
                  playbackSpeed: (index + 1) / 4,
                }));
              }}
              settingSection={SettingSections.main}
              isActive={videoFewUpdatesContext.playbackSpeed === (index + 1) / 4}
              value={
                item === 1
                  ? i18n?.settingsPlaybackSpeedNormal || defaultI18n.settingsPlaybackSpeedNormal
                  : item
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default SettingSpeeds;
