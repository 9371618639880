import IconCheck from "../../components/Icons/IconCheck";
import { SettingSections, useSettingsContext } from "../../contexts/SettingsContext";
import { useSubtitleStateContext } from "../../contexts/SubtitleStateContext";
import { ISubtitle } from "../../types";

interface SubtitleAdvancedSubsProps {
  subtitles: ISubtitle[];
  nameSectionLangs: SettingSections;
}

const SubtitleAdvancedSubs = ({ subtitles, nameSectionLangs }: SubtitleAdvancedSubsProps) => {
  const { mediaState, setMediaState } = useSubtitleStateContext();
  const { setActiveSection } = useSettingsContext();
  return (
    <div className="embed-settings-py">
      {subtitles.map((sub, index) => (
        <div
          key={sub.url}
          onClick={() => {
            setMediaState((prevState) => ({
              ...prevState,
              subtitleSelected: sub,
              subtitleLangNameSelected: prevState.subtitleLangNameSelected,
            }));
            setActiveSection(nameSectionLangs);
          }}
          className="embed-settings-item-2"
        >
          {mediaState.subtitleSelected?.url === sub.url ? (
            <IconCheck className="embed-chevron" />
          ) : (
            <div style={{ width: 15, height: 15, flexShrink: 0 }}></div>
          )}
          <span>
            {sub.language} {index + 1}
          </span>
        </div>
      ))}
    </div>
  );
};

export default SubtitleAdvancedSubs;
