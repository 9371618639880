import { useSearchParams } from "react-router-dom";
import SeriesSourceEpisodesPicker from "./SeriesSourceEpisodesPicker";
import SeriesSourcesSeasonsPicker from "./SeriesSourceSeasonsPicker";
import { useState } from "react";
import { ISeason, TMDBType } from "backend/tmdb/types";
import PopoverSourcesPicker from "components/PopoverSourcesPicker";
import { isMobile } from "react-device-detect";
import * as Popover from "@radix-ui/react-popover";
import { imageTMDB } from "backend/tmdb/image";
import IconClose from "./Icons/IconClose";

interface SeriesSourcesPickerProps {
  details: TMDBType.ISeriesDetails;
}

export type SectionType = "episodes" | "seasons";

const SeriesSourcesPicker = ({ details }: SeriesSourcesPickerProps) => {
  const [searchParams] = useSearchParams();
  const seasonId = Number(searchParams.get("season") || 1);
  const [selectedSeasonId, setSelectedSeasonId] = useState(Number(seasonId));
  const [activeSection, setActiveSection] = useState<SectionType>("episodes");
  const handleChangeSection = (sectionName: SectionType) => {
    setActiveSection(sectionName);
  };
  const handleChangeSelectSeason = (season: ISeason) => {
    setSelectedSeasonId(season.season_number);
    setActiveSection("episodes");
  };
  return (
    <PopoverSourcesPicker>
      {isMobile && (
        <Popover.Close className="mt-4 ml-3">
          <IconClose className="w-8 h-8" />
        </Popover.Close>
      )}
      {activeSection === "seasons" ? (
        <SeriesSourcesSeasonsPicker
          seasons={details.seasons}
          handleChangeSelectSeason={handleChangeSelectSeason}
        />
      ) : (
        <SeriesSourceEpisodesPicker
          fallbackSrc={imageTMDB.image300(details.backdrop_path)}
          selectedSeasonId={selectedSeasonId}
          handleChangeSection={handleChangeSection}
        />
      )}
    </PopoverSourcesPicker>
  );
};

export default SeriesSourcesPicker;
