import { i18nType } from "../types";

export const defaultI18n: i18nType = {
  tooltipsServers: "Servers",
  tooltipsEpisodes: "Episodes",
  settingsSubtitlesAdvanced: "Subtitle advanced",
  settingsOpensubtitles: "Provider #1",
  settingsYifysubtitles: "Yifysubtitles",
  tooltipsPlay: "Play (k)",
  tooltipsPause: "Pause (k)",
  tooltipsMute: "Mute (m)",
  tooltipsUnmute: "Unmute (m)",
  tooltipsPicInPic: "Picture in Picture",
  tooltipsScreenshot: "Screenshot",
  tooltipsSubtitles: "Subtitles (c)",
  tooltipsSettings: "Settings",
  tooltipsFullscreen: "Full Screen (f)",
  tooltipsExitFullscreen: "Exit full screen (f)",
  settingsPlaybackSpeed: "Playback Speed",
  settingsPlaybackSpeedNormal: "Normal",
  settingsLanguage: "Language",
  settingsSubtitles: "Subtitles",
  settingsCustomSubtitles: "Subtitle settings",
  settingsUploadSubtitle: "Upload subtitle (srt,vtt)",
  settingsSubtitlesOff: "Off",
  settingsQuality: "Quality",
  settingsModalOff: "OK",
  settingsFontSize: "Font Size",
  settingsBackgroundOpacity: "Background Opacity",
  settingsTextStyle: "Text Style",
  settingsFontOpacity: "Font Opacity",
  settingsReset: "Reset",
  settingsSyncSub: "Sync sub",
  settingsLatency: "Latency",
  forward: (time: number) => `Forward ${time || 10} seconds`,
  backward: (time: number) => `Backward ${time || 10} seconds`,
  tooEarly: "Use this if subtitles are shown {{miliseconds}} ms too early",
  tooLate: "Use this if subtitles are shown {{miliseconds}} ms too late",
  subtitleSyncNoDelay: "No subtitle delay",
  settingsColor: "Color"
};

export const portugueseI18n: i18nType = {
  tooltipsServers: "Servidores",
  tooltipsEpisodes: "Episódios",
  settingsSubtitlesAdvanced: "Legendas Extras",
  settingsOpensubtitles: "Provedor #1",
  tooltipsPlay: "Reproduzir (k)",
  tooltipsPause: "Pausar (k)",
  tooltipsMute: "Mudo (m)",
  tooltipsUnmute: "Som (m)",
  tooltipsPicInPic: "Imagem minimizada",
  tooltipsScreenshot: "Captura de tela",
  tooltipsSubtitles: "Legendas (c)",
  tooltipsSettings: "Configurações",
  settingsLanguage: "Linguagem",
  tooltipsFullscreen: "Tela cheia (f)",
  tooltipsExitFullscreen: "Sair da tela cheia (f)",
  settingsPlaybackSpeed: "Velocidade de reprodução",
  settingsPlaybackSpeedNormal: "Normal",
  settingsSubtitles: "Legendas",
  settingsCustomSubtitles: "Configurações de legenda",
  settingsUploadSubtitle: "Carregar legenda (srt,vtt)",
  settingsSubtitlesOff: "Desativado",
  settingsQuality: "Qualidade",
  settingsModalOff: "OK",
  settingsFontSize: "Tamanho da fonte",
  settingsBackgroundOpacity: "Opacidade do fundo",
  settingsTextStyle: "Estilo do texto",
  settingsFontOpacity: "Opacidade da fonte",
  settingsReset: "Redefinir",
  settingsLatency: "Latência",
  settingsSyncSub: "Sincronizar legenda",
  forward: (time: number) => `Avançar ${time || 10} segundos`,
  backward: (time: number) => `Voltar ${time || 10} segundos`,
  tooEarly: "Usar se as legendas aparecerem {{miliseconds}} ms muito cedo",
  tooLate: "Usar se as legendas aparecerem {{miliseconds}} ms muito tarde",
  subtitleSyncNoDelay: "Sem atraso de legenda",
  settingsYifysubtitles: "Yifysubtitles",
  settingsColor: "Cor"
};

export const spanishI18n: i18nType = {
  tooltipsServers: "Servidores",
  tooltipsEpisodes: "Episodios",
  settingsSubtitlesAdvanced: "Legendas Extras",
  settingsOpensubtitles: "Provedor #1",
  tooltipsPlay: "Reproducir (k)",
  tooltipsPause: "Pausar (k)",
  tooltipsMute: "Silenciar (m)",
  tooltipsUnmute: "Activar sonido (m)",
  tooltipsPicInPic: "Imagen en imagen",
  tooltipsScreenshot: "Captura de pantalla",
  tooltipsSubtitles: "Subtítulos (c)",
  tooltipsSettings: "Configuraciones",
  tooltipsFullscreen: "Pantalla completa (f)",
  tooltipsExitFullscreen: "Salir de pantalla completa (f)",
  settingsPlaybackSpeed: "Velocidad de reproducción",
  settingsPlaybackSpeedNormal: "Normal",
  settingsLanguage: "Idioma",
  settingsSubtitles: "Subtítulos",
  settingsCustomSubtitles: "Configuración de subtítulos",
  settingsUploadSubtitle: "Cargar subtítulo (srt,vtt)",
  settingsSubtitlesOff: "Apagado",
  settingsQuality: "Calidad",
  settingsModalOff: "Aceptar",
  settingsFontSize: "Tamaño de fuente",
  settingsBackgroundOpacity: "Opacidad de fondo",
  settingsTextStyle: "Estilo de texto",
  settingsFontOpacity: "Opacidad de fuente",
  settingsReset: "Restablecer",
  settingsLatency: "Latência",
  settingsSyncSub: "Sincronizar subtítulo",
  forward: (time: number) => `Avanzar ${time || 10} segundos`,
  backward: (time: number) => `Retroceder ${time || 10} segundos`,
  tooEarly: `Usar esto si los subtítulos se muestran {{miliseconds}} ms demasiado temprano`,
  tooLate: `Usar esto si los subtítulos se muestran {{miliseconds}} ms demasiado tarde`,
  subtitleSyncNoDelay: "Sin retraso en el subtítulo",
  settingsYifysubtitles: "Yifysubtitles",
  settingsColor: "Cor"
};

export const player18n = {
  en: defaultI18n,
  pt: portugueseI18n,
  es: spanishI18n
};
