import { PATH_API } from "../../../constants";
import axios from "axios";
import { IMediaSource } from "../types";

export const getSources = async (url: string) => {
  const { data: detailsEmbed } = await axios.get<string>(PATH_API.BYPASS_CORS_CORIO(url));
  const indexOfStartMp4 = detailsEmbed.indexOf("initializePlayer('");
  const mp4Url = detailsEmbed.slice(
    indexOfStartMp4 + 18,
    detailsEmbed.indexOf("',", indexOfStartMp4)
  );
  return { sources: [{ quality: "HD", url: mp4Url }], subtitles: [] };
};

export const getSourcesWebsocket = async (detailsEmbed: string): Promise<IMediaSource> => {
  const indexOfStartYoucineKey = detailsEmbed.indexOf("getUrlFromYoucine('youcine://");
  const youcineKey = detailsEmbed.slice(
    indexOfStartYoucineKey + 29,
    detailsEmbed.indexOf("');", indexOfStartYoucineKey)
  );
  if (!youcineKey || youcineKey.length > 32) throw new Error("Invalid youcineKey!");
  const { data: sources } = await axios.get<IMediaSource>(
    `https://proxy-visioncine-three.vercel.app/visioncine?youcineKey=${youcineKey}`
  );
  return sources;
};
