import { AnchorHTMLAttributes } from "react";
import LazyImage from "./LazyImage";
import classNames from "utils/classNames";
import { Link } from "react-router-dom";

interface EpisodeInfoProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  title: string;
  description: string;
  imageUrl: string;
  fallbackSrc?: string;
}

const EpisodeInfo = ({
  imageUrl,
  title,
  description,
  fallbackSrc,
  className,
  href,
  ...props
}: EpisodeInfoProps) => {
  return (
    <Link
      to={href || "/"}
      className={classNames("rounded-sm aspect-video overflow-hidden", className)}
      {...props}
    >
      <div className="relative w-full cursor-pointer">
        <div className="w-full h-full">
          <LazyImage
            src={imageUrl}
            fallbackSrc={fallbackSrc}
            className="object-cover w-full h-full aspect-video"
          />
        </div>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/70" />
        <div className="absolute bottom-0 w-full p-2 space-y-2">
          <p className="text-sm font-semibold leading-none line-clamp-1">{title}</p>
          <p className="text-xs font-semibold leading-none text-gray-300 line-clamp-1">
            {description}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default EpisodeInfo;
