import { useQuery } from "@tanstack/react-query";
import { ExtractFnReturnType, QueryConfig, queryClient } from "libs/react-query";
import axiosTmdb from "./axiosTmdb";
import { TMDBMediaType, TMDBType } from "./types";

export const getMovieDetailsTmdb = async (
  params: IUseMovieDetailsTmdbOptions
): Promise<TMDBType.IMovieDetails | null> => {
  try {
    const promiseResults = await Promise.all([
      axiosTmdb(
        `/${TMDBMediaType.Movie}/${params.tmdb_id}?append_to_response=external_ids,translations&language=en`
      ),
      axiosTmdb(
        `/${TMDBMediaType.Movie}/${params.tmdb_id}?append_to_response=external_ids,translations&language=pt-BR`
      ),
    ]);
    const [detailsEnglish, detailsPortuguese] =
      promiseResults as unknown as TMDBType.IMovieDetails[];
    detailsPortuguese.title_portuguese =
      detailsPortuguese.title ||
      detailsPortuguese.translations.translations.find(
        (t) => t && t?.iso_639_1 === "pt" && t?.data?.name
      )?.data?.name ||
      detailsPortuguese.original_title;
    detailsPortuguese.title_english =
      detailsEnglish.title ||
      detailsEnglish.translations.translations.find((t) => t?.iso_639_1 === "en" && t?.data?.name)
        ?.data.name ||
      detailsEnglish.original_title;
    return detailsPortuguese;
  } catch (error) {
    console.log("error: ", error);
    return null;
  }
};

type QueryFnType = typeof getMovieDetailsTmdb;

interface IUseMovieDetailsTmdbOptions {
  tmdb_id: string;
}

type UseMovieDetailsTmdbOptions = {
  params: IUseMovieDetailsTmdbOptions;
  config?: QueryConfig<QueryFnType>;
};

const primaryKey = "MovieDetailsTmdb";

export const useMovieDetailsTmdb = ({ config = {}, params }: UseMovieDetailsTmdbOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [primaryKey, params],
    queryFn: () => getMovieDetailsTmdb(params),
  });
};

export const getQueryDataMovieDetailsTmdb = (params: IUseMovieDetailsTmdbOptions) => {
  return queryClient.getQueryData([primaryKey, params]) as TMDBType.IMovieDetails;
};
