import classNames from "../../../utils/classNames";
import { memo } from "react";
import SettingMain from "./SettingMain";
import { useSettingsContext } from "../../contexts/SettingsContext";
import SettingQualities from "./SettingQualities";
import SettingSpeeds from "./SettingSpeeds";
import SettingSubtitles from "./SettingSubtitles";

interface SettingDialogProps {
  isShow: boolean;
}

const SettingDialog = ({ isShow }: SettingDialogProps) => {
  const { height } = useSettingsContext();
  return (
    <div className={classNames("embed-settings-dialog", isShow && "embed-show")}>
      {isShow ? (
        <>
          <div style={{ height }} className="embed-settings-dialog-outer">
            <SettingMain />
            <SettingSpeeds />
            <SettingSubtitles />
            <SettingQualities />
          </div>
          <div className="embed-settings-overlay"></div>
        </>
      ) : null}
    </div>
  );
};

export default memo(SettingDialog);
