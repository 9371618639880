import React from "react";
import { WRAPPER_PLAYER_ID } from "../player/constants";
import IconGoBack from "../player/components/Icons/IconGoBack";

interface EmbedTmdbPlayerProps {
  urlEmbed: string;
  handleGoBack: () => void;
}

const EmbedTmdbPlayer = ({ urlEmbed, handleGoBack }: EmbedTmdbPlayerProps) => {
  return (
    <div className="fixed inset-0" id={WRAPPER_PLAYER_ID}>
      <div className="absolute top-0 left-0 right-0 z-10 flex items-center justify-end visible h-24 px-4 md:px-10 gap-x-5 md:gap-x-8">
        <IconGoBack
          className="flex-shrink-0 w-10 h-10 cursor-pointer braflix-icon-lg braflix-scale"
          onClick={handleGoBack}
        />
      </div>
      <embed type="text/html" src={urlEmbed} width="100%" height="100%" />
    </div>
  );
};

export default EmbedTmdbPlayer;
