import axios from "axios";
import { load } from "cheerio";
import { baseUrlLoveflix } from "./constants";
import { search } from "./search";
import { getMp4fromEmbed } from "./utils";
import { IMediaSource, IParamsMediaSource } from "../types";
import { PATH_API } from "../../../constants";

export const getMediaSourcesLoveflix = async ({
  extraData,
}: IParamsMediaSource): Promise<IMediaSource> => {
  let title = extraData.titlePortuguese;
  console.log("title: ", title);
  const foundMovieHref = await search({ title, extraData });
  if (!foundMovieHref) throw new Error("Unable to fetch movie stream");
  if (foundMovieHref.startsWith(`${baseUrlLoveflix}/movie`)) {
    const urlMp4 = await getMp4fromEmbed(foundMovieHref);
    return { sources: [{ quality: "HD", url: urlMp4 }], subtitles: [] };
  } else {
    const { data: htmlDetails } = await axios(PATH_API.BYPASS_CORS_WORKERS(foundMovieHref));
    const $ = load(htmlDetails);
    const episodes: string[] = [];
    $(".episodes a").each((_, eleEpisode) => {
      episodes.push($(eleEpisode).attr("href")!);
    });
    if (episodes?.length === 0) throw new Error("Unable to fetch movie stream");
    const foundEpisodeSeries = episodes.find((ep) =>
      ep.includes(`${extraData?.seasonId}-season-${extraData?.episodeId}-episode`)
    );
    const urlMp4 = await getMp4fromEmbed(foundEpisodeSeries || episodes[0]);
    return { sources: [{ url: urlMp4, quality: "HD" }], subtitles: [] };
  }
};
