import IconCloudServers from "player/components/Icons/IconCloudServers";
import IconSourceError from "player/components/Icons/IconSourceError";
import { defaultI18n } from "player/constants/i18n";
import { useI18nContext } from "player/contexts/I18nContext";
import { queryClient } from "libs/react-query";
import classNames from "utils/classNames";
import * as Popover from "@radix-ui/react-popover";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import IconLoadingServer from "./IconLoadingServer";
import styles from "./index.module.scss";
import { WRAPPER_PLAYER_ID } from "player/constants";
import IconEpHover from "components/Icons/IconEpHover";
import IconArrowRight from "components/Icons/IconArrowRight";
import useVideoServersStore from "store";
import { IParamsMediaSource, IServer } from "backend/movie-providers/types";

interface PopoverServersPickerProps extends Popover.PopoverContentProps {
  isLoading: boolean;
  paramsMediaSources: IParamsMediaSource;
}

const PopoverServersPicker = ({
  children,
  isLoading,
  paramsMediaSources,
  ...props
}: PopoverServersPickerProps) => {
  const [containerSelector, setContainerSelector] = useState<HTMLElement | null>(null);
  const { activeServer, servers, setActiveServer, setServers } = useVideoServersStore(
    (state) => state
  );
  const { i18n } = useI18nContext();
  const changeServerMutation = useMutation(
    async (server: IServer) => {
      const mediaSources = await server.get(paramsMediaSources);
      return mediaSources;
    },
    {
      onError(_error, variables, _context) {
        const cloneServers = [...servers];
        const foundIndexServer = cloneServers.findIndex((x) => x.name === variables.name);
        cloneServers[foundIndexServer].isSuccess = false;
        setServers(cloneServers);
      },
      onSuccess: (newMediaSources, variables) => {
        setActiveServer(variables.name);
        queryClient.setQueryData(["mediaSources", paramsMediaSources], {
          mediaSources: newMediaSources,
          servers: [],
        });
      },
    }
  );
  const [itemLoading, setItemLoading] = useState("");
  useEffect(() => {
    setContainerSelector(document.querySelector(`#${WRAPPER_PLAYER_ID}`) as HTMLElement);
  }, []);
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button
          className="embed-tooltips-right embed-scale embed-icon-lg"
          data-embed-tooltips={i18n?.tooltipsServers || defaultI18n.tooltipsServers}
        >
          <IconCloudServers />
        </button>
      </Popover.Trigger>
      <Popover.Portal container={containerSelector as HTMLElement}>
        <Popover.Content
          className={classNames(
            styles.PopoverContent,
            "bg-[#0e1315] flex flex-col scrollbar-hidden border-r border-zinc-700 px-6 py-4 overflow-y-auto rounded-[10px] md:border-none popover-server-picker"
          )}
          sideOffset={isDesktop ? 50 : -40}
          collisionPadding={{ right: isDesktop ? 85 : 0, left: 0 }}
          {...props}
        >
          <div className="pb-3 text-white md:pb-4">
            <p className="font-semibold md:text-xl">Escolha uma opção</p>
            <span className="font-light"></span>
          </div>
          <div className="grid h-full grid-rows-[minmax(1px,1fr)]">
            <ul className="space-y-1">
              {servers.map((server) => (
                <li
                  key={server.name}
                  onClick={() => {
                    if (isLoading) return;
                    changeServerMutation.mutate(server);
                    setItemLoading(server.name);
                  }}
                  className={classNames(
                    "group flex min-h-[48px] cursor-pointer items-center justify-between space-x-1 rounded -mx-2 p-2 text-sm lg:text-base font-medium transition-[background-color,color] duration-150 md:hover:bg-zinc-800 md:hover:text-blue-300 border-zinc-700 text-white",
                    activeServer === server.name && "border"
                  )}
                >
                  <div className="overflow-hidden">
                    <div
                      className={classNames(
                        "truncate",
                        activeServer === server.name && "text-primary font-bold"
                      )}
                    >
                      {server.name}
                    </div>
                    <div className="mt-1 text-sm font-normal text-zinc-500">{server.note}</div>
                  </div>
                  <div className="relative h-5 w-5 min-w-[1rem]">
                    {activeServer === server.name && server.isSuccess ? (
                      <IconEpHover className="absolute inset-0 w-5 h-5" />
                    ) : isLoading ||
                      (changeServerMutation.isLoading && itemLoading === server.name) ? (
                      <IconLoadingServer />
                    ) : server.isSuccess ? (
                      <span className="absolute inset-0 translate-x-2 text-primary opacity-0 transition-[opacity,transform] duration-100 group-hover:translate-x-0 md:group-hover:opacity-100">
                        <IconArrowRight className="w-5 h-5 text-white" />
                      </span>
                    ) : (
                      <span className="absolute inset-0 text-red-600">
                        <IconSourceError />
                      </span>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default PopoverServersPicker;
