import { ISource, ISubtitle } from "player";

export const sortSubtitlesFlixhq = (subtitles: ISubtitle[]) => {
  return subtitles.map((subtitle, index) => ({
    lang: `${subtitle.lang} ${index}`,
    language: subtitle.lang,
    url: subtitle.url,
  }));
};

export const sortSourcesFlixhq = (sources: ISource[]) => {
  return sources.map((source) => ({
    url: source.url,
    quality: source.quality === "auto" ? "(Auto)" : `${source.quality}P`,
  }));
};

export const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const substringAfter = (str: string, toFind: string) => {
  const index = str.indexOf(toFind);
  return index === -1 ? "" : str.substring(index + toFind.length);
};

export const substringBefore = (str: string, toFind: string) => {
  const index = str.indexOf(toFind);
  return index === -1 ? "" : str.substring(0, index);
};
