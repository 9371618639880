import { defaultI18n } from "../../constants/i18n";
import { useI18nContext } from "../../contexts/I18nContext";
import { useSubtitleStateContext } from "../../contexts/SubtitleStateContext";

const SettingSubtitleColor = () => {
  const { i18n } = useI18nContext();
  const { setMediaState } = useSubtitleStateContext();
  const handleChangeColorSubtitle = (color: string) => {
    setMediaState((prevState) => ({
      ...prevState,
      subtitleSettings: { ...prevState.subtitleSettings, color },
    }));
  };
  return (
    <div className="flex items-center min-h-[60px] text-lg px-[15px] justify-between">
      <div className="embed-settings-item-left">
        {i18n.settingsColor || defaultI18n.settingsColor}
      </div>
      <div className="flex items-center gap-x-[10px]">
        <div
          className="bg-white rounded-full cursor-pointer w-7 h-7"
          onClick={() => handleChangeColorSubtitle("#ffffff")}
        ></div>
        <div
          className="bg-[#ff4370] w-7 h-7 rounded-full cursor-pointer"
          onClick={() => handleChangeColorSubtitle("#ff4370")}
        ></div>
        <div
          className="bg-[#00d4a1] w-7 h-7 rounded-full cursor-pointer"
          onClick={() => handleChangeColorSubtitle("#00d4a1")}
        ></div>
        <div
          className="bg-[#ffb119] w-7 h-7 rounded-full cursor-pointer"
          onClick={() => handleChangeColorSubtitle("#ffb119")}
        ></div>
        <div
          className="bg-[#ff0000] w-7 h-7 rounded-full cursor-pointer"
          onClick={() => handleChangeColorSubtitle("#ff0000")}
        ></div>
      </div>
    </div>
  );
};

export default SettingSubtitleColor;
