import { ChangeEvent, useState } from "react";
import { useGlobalStateContext } from "../../contexts/GlobalStateContext";
import { useSubtitleStateContext } from "../../contexts/SubtitleStateContext";
import ChevronRight from "../../components/Icons/ChevronRight";
import IconUpload from "../../components/Icons/IconUpload";
import { defaultI18n } from "../../constants/i18n";
import { useI18nContext } from "../../contexts/I18nContext";

const SubtitleUploader = () => {
  const { setMediaState } = useSubtitleStateContext();
  const { setGlobalState } = useGlobalStateContext();
  const { i18n } = useI18nContext();
  const [lengthMySubs, setLengthMySubs] = useState(0);
  const handleUploadSubtitle = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const uploadedFile = e.target.files?.[0];
      if (!uploadedFile) return;
      const isSRT = uploadedFile?.name.includes("srt");
      const isVTT = uploadedFile?.name.includes("vtt");
      if (!isVTT && !isSRT) return;
      // throw new Error("Only SRT or VTT files are allowed");
      const textString = (await uploadedFile?.text()) || "";
      const blobVTT = new Blob([textString], {
        type: `text/${isSRT ? "srt" : "vtt"};charset=ISO-8859-1`,
      });
      const path = URL.createObjectURL(blobVTT) + `#.${isSRT ? "srt" : "vtt"}`;
      setGlobalState((prevState: any) => ({
        ...prevState,
        subtitles: [
          {
            lang: `Upload ${lengthMySubs + 1}`,
            language: `Upload ${lengthMySubs + 1}`,
            url: path,
          },
          ...prevState.subtitles,
        ],
      }));
      setMediaState((prevState) => ({ ...prevState, subtitleIndex: 0 }));
      setLengthMySubs((prevLength) => prevLength + 1);
    } catch (error) {
      console.log("error: ", error);
    }
  };
  return (
    <div style={{ position: "relative", display: "none" }} className="embed-settings-item">
      <input
        type="file"
        accept=".vtt,.srt"
        className="embed-upload-subtitle"
        onChange={handleUploadSubtitle}
      />
      <div className="embed-settings-item-left">
        <IconUpload className="embed-icon-md" />
        <span>{i18n?.settingsUploadSubtitle || defaultI18n.settingsUploadSubtitle}</span>
      </div>
    </div>
  );
};

export default SubtitleUploader;
