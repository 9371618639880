import { memo } from "react";
import { useHoverStateContext } from "../../contexts/HoverStateContext";
import { useVideoFewUpdatesContext } from "../../contexts/VideoFewUpdatesContext";
import { useVideoLotsOfUpdatesContext } from "../../contexts/VideoLotsOfUpdatesContext";
import ButtonBackward from "../buttons/ButtonBackward";
import ButtonForward from "../buttons/ButtonForward";
import ButtonPlay from "../buttons/ButtonPlay";
import CircularProgress from "../../components/Icons/CircularProgress";

interface MobileCenterControlsMemoProps {
  isHoverEnabled: boolean;
  handleClearTimeout: () => void;
  updateHoverState: () => void;
  loadedData: boolean;
  loading: boolean;
  paused: boolean;
}

const MobileCenterControlsMemo = memo(
  ({
    loadedData,
    loading,
    paused,
    handleClearTimeout,
    isHoverEnabled,
    updateHoverState
  }: MobileCenterControlsMemoProps) => {
    return (
      <div className={`${isHoverEnabled ? "overlayContainer" : "inactive"}`}>
        <div className="playerControlsContainer">
          <div
            className="playerControlsInnerContainer"
            onClick={updateHoverState}
            onMouseEnter={handleClearTimeout}
          >
            <ButtonBackward />
            <div className="buttonPlay">
              {!loadedData || (loading && !paused) ? <CircularProgress /> : <ButtonPlay />}
            </div>
            <ButtonForward />
          </div>
        </div>
      </div>
    );
  }
);

MobileCenterControlsMemo.displayName = "MobileCenterControlsMemo";

const MobileCenterControls = () => {
  const { videoFewUpdatesContext } = useVideoFewUpdatesContext();
  const { videoLotsOfUpdatesContext } = useVideoLotsOfUpdatesContext();
  const { updateHoverState, isHoverEnabled, handleClearTimeout } = useHoverStateContext();
  return (
    <MobileCenterControlsMemo
      isHoverEnabled={isHoverEnabled}
      handleClearTimeout={handleClearTimeout}
      loadedData={videoFewUpdatesContext.loadedData}
      loading={videoFewUpdatesContext.loading}
      paused={videoLotsOfUpdatesContext.paused}
      updateHoverState={updateHoverState}
    />
  );
};

export default MobileCenterControls;
