export const flags = {
  "Portuguese (BR)": "https://flagsapi.com/BR/flat/24.png",
  Portuguese: "https://flagsapi.com/PT/flat/24.png",
  Mozambique: "https://flagsapi.com/PT/flat/24.png",
  Abkhazian: "https://flagsapi.com/AK/flat/64.png",
  Afrikaans: "https://flagsapi.com/CF/flat/24.png",
  Albanian: "https://flagsapi.com/AL/flat/24.png",
  Arabic: "https://flagsapi.com/SA/flat/24.png",
  Aragonese: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Armenian: "https://flagsapi.com/AM/flat/24.png",
  Assamese: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Asturian: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Azerbaijani: "https://flagsapi.com/AZ/flat/24.png",
  Basque: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Belarusian: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Bengali: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Bosnian: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Breton: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Bulgarian: "https://flagsapi.com/BE/flat/24.png",
  Burmese: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Catalan: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  "Chinese (simplified)": "https://flagsapi.com/CN/flat/24.png",
  "Chinese Bilingual": "https://flagsapi.com/CN/flat/24.png",
  "Chinese (traditional)": "https://flagsapi.com/CN/flat/24.png",
  Czech: "https://flagsapi.com/CZ/flat/24.png",
  Danish: "https://flagsapi.com/DK/flat/24.png",
  Dutch: "https://flagsapi.com/NL/flat/24.png",
  Greek: "https://flagsapi.com/GR/flat/24.png",
  English: "https://flagsapi.com/GB/flat/24.png",
  Esperanto: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Estonian: "https://flagsapi.com/EE/flat/24.png",
  Extremaduran: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Finnish: "https://flagsapi.com/FI/flat/24.png",
  French: "https://flagsapi.com/FR/flat/24.png",
  Georgian: "https://flagsapi.com/GE/flat/24.png",
  German: "https://flagsapi.com/DE/flat/24.png",
  Gaelic: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Irish: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Galician: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Hebrew: "https://flagsapi.com/IL/flat/24.png",
  Hindi: "https://flagsapi.com/IN/flat/24.png",
  Croatian: "https://flagsapi.com/HR/flat/24.png",
  Hungarian: "https://flagsapi.com/HU/flat/24.png",
  Igbo: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Icelandic: "https://flagsapi.com/IS/flat/24.png",
  Interlingua: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Indonesian: "https://flagsapi.com/ID/flat/24.png",
  Italian: "https://flagsapi.com/IT/flat/24.png",
  Japanese: "https://flagsapi.com/JP/flat/24.png",
  Kannada: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Kazakh: "https://flagsapi.com/KZ/flat/24.png",
  Khmer: "https://flagsapi.com/KH/flat/24.png",
  Korean: "https://flagsapi.com/KR/flat/24.png",
  Kurdish: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Latvian: "https://flagsapi.com/LV/flat/24.png",
  Lithuanian: "https://flagsapi.com/LT/flat/24.png",
  Luxembourgish: "https://flagsapi.com/LU/flat/24.png",
  Macedonian: "https://flagsapi.com/MK/flat/24.png",
  Malayalam: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Marathi: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Malay: "https://flagsapi.com/MY/flat/24.png",
  Montenegrin: "https://flagsapi.com/ME/flat/24.png",
  Manipuri: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Mongolian: "https://flagsapi.com/MN/flat/24.png",
  Navajo: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Nepali: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Norwegian: "https://flagsapi.com/NO/flat/24.png",
  Occitan: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Odia: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Persian: "https://flagsapi.com/IR/flat/24.png",
  Polish: "https://flagsapi.com/PL/flat/24.png",
  Dari: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Pushto: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Romanian: "https://flagsapi.com/RO/flat/24.png",
  Russian: "https://flagsapi.com/RU/flat/24.png",
  Santali: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Serbian: "https://flagsapi.com/RS/flat/24.png",
  Sinhalese: "https://flagsapi.com/LK/flat/24.png",
  Slovak: "https://flagsapi.com/SK/flat/24.png",
  Slovenian: "https://flagsapi.com/SI/flat/24.png",
  "Northern Sami": "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Sindhi: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Somali: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Spanish: "https://flagsapi.com/ES/flat/24.png",
  "Spanish (LA)": "https://flagsapi.com/ES/flat/24.png",
  "Spanish (EU)": "https://flagsapi.com/ES/flat/24.png",
  Swahili: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Swedish: "https://flagsapi.com/SE/flat/24.png",
  Syriac: "https://flagsapi.com/SY/flat/24.png",
  Tamil: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Tatar: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Telugu: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Tagalog: "https://flagsapi.com/PH/flat/24.png",
  Thai: "https://flagsapi.com/TH/flat/24.png",
  Pona: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Turkmen: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
  Turkish: "https://flagsapi.com/TR/flat/24.png",
  Ukrainian: "https://flagsapi.com/UA/flat/24.png",
  Urdu: "https://flagsapi.com/PK/flat/24.png",
  Vietnamese: "https://flagsapi.com/VN/flat/24.png",
  Welsh: "https://www.otherworldproject.com/wiki/images/9/96/Unknown_flag.png",
};
