import React, { createContext, useCallback, useContext, useMemo, useRef } from "react";
import { useVideoLotsOfUpdatesContext } from "./VideoLotsOfUpdatesContext";
import { useVideoUpdateContext } from "./VideoUpdateContext";

interface HoverStateProviderProps {
  children: React.ReactNode;
}

export const defaultHoverState = {
  timeoutRef: null,
  updateHoverState: () => {},
  isHoverEnabled: true,
  handleClearTimeout: () => {}
};

interface IHoverStateContext {
  timeoutRef: React.MutableRefObject<any> | null;
  updateHoverState: () => void;
  isHoverEnabled: boolean;
  handleClearTimeout: () => void;
}

const HoverStateContext = createContext<IHoverStateContext>(defaultHoverState);

function HoverStateProvider(props: HoverStateProviderProps) {
  const {
    videoLotsOfUpdatesContext: { paused, settingsActive }
  } = useVideoLotsOfUpdatesContext();
  const {
    videoUpdate: { hoverEnabled },
    setVideoUpdate
  } = useVideoUpdateContext();
  const timeoutRef = useRef<any>(null);
  const updateHoverState = useCallback(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setVideoUpdate((prevState) => ({
      ...prevState,
      hoverEnabled: true
    }));
    timeoutRef.current = setTimeout(() => {
      setVideoUpdate((prevState) => ({
        ...prevState,
        hoverEnabled: false
      }));
    }, 5000);
  }, [timeoutRef, setVideoUpdate]);
  const isHoverEnabled = useMemo(
    () => hoverEnabled || paused || settingsActive,
    [hoverEnabled, paused, settingsActive]
  );
  const handleClearTimeout = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef?.current);
  }, []);
  const value = useMemo(
    () => ({ timeoutRef, updateHoverState, isHoverEnabled, handleClearTimeout }),
    [handleClearTimeout, isHoverEnabled, updateHoverState]
  );
  return <HoverStateContext.Provider value={value}>{props.children}</HoverStateContext.Provider>;
}

function useHoverStateContext() {
  const context = useContext(HoverStateContext);
  if (typeof context === "undefined") {
    throw new Error("useHoverStateContext must be used within a HoverStateProvider");
  }
  return context;
}

export { HoverStateProvider, useHoverStateContext };
