import { ButtonHTMLAttributes, Dispatch, RefObject, memo, useEffect, useRef } from "react";
import { isIOS, isMobile } from "react-device-detect";
import IconEnterFullScreen from "../../components/Icons/IconEnterFullScreen";
import IconExitFullScreen from "../../components/Icons/IconExitFullScreen";
import { WRAPPER_PLAYER_ID } from "../../constants";
import { defaultI18n } from "../../constants/i18n";
import { useGlobalStateContext } from "../../contexts/GlobalStateContext";
import { useHoverStateContext } from "../../contexts/HoverStateContext";
import { useI18nContext } from "../../contexts/I18nContext";
import {
  IVideoLotsOfUpdates,
  useVideoLotsOfUpdatesContext,
} from "../../contexts/VideoLotsOfUpdatesContext";
import { useEffectUpdate } from "../../hooks/useEffectUpdate";
import { IKeyBoardShortcut } from "../../types";
import {
  checkOnFullScreen,
  getRequestExistFullScreen,
  getRequestFullScreen,
} from "../../utils/fullscreen";

interface ButtonFullscreenMemoProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onFullScreen: boolean;
  playerRef: RefObject<HTMLVideoElement> | undefined;
  keyboardShortcut: boolean | IKeyBoardShortcut | undefined;
  setVideoLotsOfUpdatesContext: Dispatch<React.SetStateAction<IVideoLotsOfUpdates>>;
  updateHoverState: () => void;
}

const ButtonFullscreenMemo = memo((props: ButtonFullscreenMemoProps) => {
  const {
    onFullScreen,
    updateHoverState,
    playerRef,
    keyboardShortcut,
    setVideoLotsOfUpdatesContext,
    ...others
  } = props;
  const fullscreenButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const keyHandler = (e: KeyboardEvent) => {
      if (!keyboardShortcut) return;
      if ((keyboardShortcut === true || keyboardShortcut.fullScreen) && e.key === "f") {
        fullscreenButtonRef.current?.click();
      }
    };
    window.addEventListener("keyup", keyHandler);
    return () => {
      window.removeEventListener("keyup", keyHandler);
    };
  }, [keyboardShortcut]);
  useEffect(() => {
    const changeHandler = () => {
      const fullscreenElement = checkOnFullScreen();
      if (fullscreenElement) {
        if (isMobile) window.screen.orientation.lock("landscape");
        setVideoLotsOfUpdatesContext((prevState) => ({
          ...prevState,
          onFullScreen: true,
        }));
      } else {
        if (isMobile) window.screen.orientation.lock("portrait");
        setVideoLotsOfUpdatesContext((prevState) => ({
          ...prevState,
          onFullScreen: false,
        }));
      }
    };
    const player = playerRef?.current || document;
    player?.addEventListener("fullscreenchange", changeHandler);
    player?.addEventListener("webkitfullscreenchange", changeHandler);
    player?.addEventListener("mozfullscreenchange", changeHandler);
    player?.addEventListener("MSFullscreenChange", changeHandler);
    const endFullScreenHandler = () => {
      changeHandler();
      setVideoLotsOfUpdatesContext((prevState) => ({
        ...prevState,
        paused: true,
      }));
    };
    player?.addEventListener("webkitendfullscreen", endFullScreenHandler);
    return () => {
      player?.removeEventListener("fullscreenchange", changeHandler);
      player?.removeEventListener("webkitfullscreenchange", changeHandler);
      player?.removeEventListener("mozfullscreenchange", changeHandler);
      player?.removeEventListener("MSFullscreenChange", changeHandler);
      player?.removeEventListener("webkitendfullscreen", endFullScreenHandler);
    };
  }, [playerRef, setVideoLotsOfUpdatesContext]);
  useEffectUpdate(() => {
    try {
      if (onFullScreen) {
        if (isIOS) {
          const elem = playerRef?.current as HTMLVideoElement;
          const fullscreenFunction = getRequestFullScreen(elem);
          if (fullscreenFunction) {
            (fullscreenFunction as unknown as Function).call(elem);
          }
        } else {
          const elem = document.querySelector("#" + WRAPPER_PLAYER_ID) as any;
          const fullscreenFunction = getRequestFullScreen(elem);
          if (fullscreenFunction) {
            (fullscreenFunction as unknown as Function).call(elem);
          }
          if (isMobile) window.screen.orientation.lock("landscape");
        }
      } else {
        const exitFullScreen = getRequestExistFullScreen();
        if (exitFullScreen && document.fullscreenElement) {
          exitFullScreen.call(document);
        }
        if (isMobile && !isIOS) window.screen.orientation.lock("portrait");
      }
    } catch (error) {}
    updateHoverState();
  }, [onFullScreen]);
  const handleClickCapture = () => {
    setVideoLotsOfUpdatesContext((prevState) => ({
      ...prevState,
      onFullScreen: !prevState.onFullScreen,
    }));
  };
  return (
    <button ref={fullscreenButtonRef} onClickCapture={handleClickCapture} {...others}>
      {onFullScreen ? (
        <IconExitFullScreen className="embed-icon-lg" />
      ) : (
        <IconEnterFullScreen className="embed-icon-lg" />
      )}
    </button>
  );
});

ButtonFullscreenMemo.displayName = "ButtonFullscreenMemo";

const ButtonFullscreen = () => {
  const { updateHoverState } = useHoverStateContext();
  const { videoLotsOfUpdatesContext, setVideoLotsOfUpdatesContext } =
    useVideoLotsOfUpdatesContext();
  const { playerRef, keyboardShortcut } = useGlobalStateContext();
  const { i18n } = useI18nContext();
  return (
    <ButtonFullscreenMemo
      className="embed-center-container embed-tooltips-right embed-scale"
      data-embed-tooltips={`${
        videoLotsOfUpdatesContext.onFullScreen
          ? i18n?.tooltipsExitFullscreen || defaultI18n.tooltipsExitFullscreen
          : i18n?.tooltipsFullscreen || defaultI18n.tooltipsFullscreen
      }`}
      onFullScreen={videoLotsOfUpdatesContext.onFullScreen}
      playerRef={playerRef}
      keyboardShortcut={keyboardShortcut}
      setVideoLotsOfUpdatesContext={setVideoLotsOfUpdatesContext}
      updateHoverState={updateHoverState}
    />
  );
};

export default ButtonFullscreen;
