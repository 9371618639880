import { MediaType } from "types";
import { compareTitle } from "utils/helper";
import { getSourcesMovie } from "./getSourcesMovie";
import { getSourcesSeries } from "./getSourcesSeries";
import { ISearchResult } from "./types";
import { get } from "./utils";
import { IMediaSource, IParamsMediaSource } from "../types";

export const getMediaSourcesSuperstream = async ({
  title,
  extraData,
}: IParamsMediaSource): Promise<IMediaSource> => {
  const apiQuerySearch = {
    module: "Search3",
    page: 1,
    type: "all",
    keyword: title,
    pagelimit: "20",
  };
  try {
    let { data: searchResults } = await get(apiQuerySearch, true).then((r) => r.json());
    //remove searchResults that dont match the media type
    searchResults = searchResults.filter((result: ISearchResult) => {
      if (extraData.mediaType === MediaType.MOVIE) return result.box_type === 1;
      else if (extraData.mediaType === MediaType.TV) return result.box_type === 2;
      else return result;
    });
    // Sort in descending order
    // searchResults.sort((a: ISearchResult, b: ISearchResult) => {
    //   const firstRating = compareTwoStrings(title, a?.title);
    //   const secondRating = compareTwoStrings(title, b?.title);
    //   return secondRating - firstRating;
    // });
    searchResults = searchResults.filter((result: ISearchResult) =>
      compareTitle(result.title, title)
    );
    if (searchResults.length === 0) throw new Error("Unable to fetch movie stream");
    // if extraData contains a year, filter out the searchResults that don't match the year
    if (extraData && extraData.year) {
      searchResults = searchResults.filter((result: ISearchResult) => {
        return result.year === extraData.year;
      });
    }
    const foundResult = searchResults[0];
    if (!foundResult) throw new Error("Unable to fetch movie stream");
    if (extraData.mediaType === MediaType.MOVIE) {
      const { sources, subtitles } = await getSourcesMovie(foundResult.id);
      if (sources.length === 0) throw new Error("Unable to fetch movie stream");
      return { sources, subtitles };
    }
    if (extraData.mediaType === MediaType.TV) {
      const { sources, subtitles } = await getSourcesSeries(
        foundResult.id,
        Number(extraData.episodeId),
        Number(extraData.seasonId)
      );
      if (sources.length === 0) throw new Error("Unable to fetch movie stream");
      return { sources, subtitles };
    }
    throw new Error("Unable to fetch movie stream");
  } catch (error) {
    throw new Error("Unable to fetch movie stream");
  }
};
