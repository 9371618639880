import React, { createContext, useContext } from "react";
import { defaultI18n, player18n } from "../constants/i18n";
import { i18nType } from "../types";

interface I18nContextProviderProps {
  children: React.ReactNode;
}

const I18nContext = createContext<{ i18n: i18nType }>({ i18n: defaultI18n });

function I18nContextProvider(props: I18nContextProviderProps) {
  return (
    <I18nContext.Provider value={{ i18n: player18n["pt"] }}>{props.children}</I18nContext.Provider>
  );
}

function useI18nContext() {
  const context = useContext(I18nContext);
  if (typeof context === "undefined") {
    throw new Error("useI18nContext must be used within a I18nContextProvider");
  }
  return context;
}

export { I18nContextProvider, useI18nContext };
