import React, { FC, memo } from "react";
import PlayIcon from "../../components/Icons/IconPlay";

const PlayEffect: FC = () => {
  return (
    <div className="embed-fade-zoom-out embed-effect-icon">
      <PlayIcon className="embed-icon-lg" />
    </div>
  );
};

export default memo(PlayEffect);
