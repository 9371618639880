import { defaultI18n } from "player/constants/i18n";
import { useI18nContext } from "player/contexts/I18nContext";
import classNames from "utils/classNames";
import * as Popover from "@radix-ui/react-popover";
import { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import styles from "./index.module.scss";
import { WRAPPER_PLAYER_ID } from "player/constants";
import IconSources from "player/components/Icons/IconSources";

interface PopoverSourcesPickerProps extends Popover.PopoverContentProps {}

const PopoverSourcesPicker = ({ children, ...props }: PopoverSourcesPickerProps) => {
  const [containerSelector, setContainerSelector] = useState<HTMLElement | null>(null);
  useEffect(() => {
    setContainerSelector(document.querySelector(`#${WRAPPER_PLAYER_ID}`) as HTMLElement);
  }, []);
  const { i18n } = useI18nContext();
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button
          className="embed-tooltips-right embed-scale embed-icon-lg"
          data-embed-tooltips={i18n?.tooltipsEpisodes || defaultI18n.tooltipsEpisodes}
        >
          <IconSources />
        </button>
      </Popover.Trigger>
      <Popover.Portal container={containerSelector as HTMLDivElement}>
        <Popover.Content
          className={classNames(
            styles.PopoverContent,
            "bg-[#0e1315] md:max-h-[400px] overflow-y-auto scrollbar-styles rounded-[10px]"
          )}
          sideOffset={isDesktop ? 50 : -50}
          collisionPadding={{ right: isDesktop ? 40 : 0, left: 0 }}
          {...props}
        >
          {children}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default PopoverSourcesPicker;
