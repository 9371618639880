import React, { useMemo, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";

export interface IVideoLotsOfUpdates {
  paused: boolean;
  onFullScreen: boolean;
  settingsActive: boolean;
}

interface VideoStateProviderProps {
  children: React.ReactNode;
}

export interface IVideoLotsOfUpdatesContext {
  videoLotsOfUpdatesContext: IVideoLotsOfUpdates;
  setVideoLotsOfUpdatesContext: React.Dispatch<React.SetStateAction<IVideoLotsOfUpdates>>;
}

const defaultVideoState: IVideoLotsOfUpdates = {
  paused: false,
  onFullScreen: false,
  settingsActive: false
};

const VideoLotsOfUpdatesContext = createContext<IVideoLotsOfUpdatesContext>({
  videoLotsOfUpdatesContext: defaultVideoState,
  setVideoLotsOfUpdatesContext: () => {}
});

function VideoLotsOfUpdatesProvider(props: VideoStateProviderProps) {
  const [videoLotsOfUpdatesContext, setVideoLotsOfUpdatesContext] = useState(defaultVideoState);
  const value = useMemo(
    () => ({ videoLotsOfUpdatesContext, setVideoLotsOfUpdatesContext }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      videoLotsOfUpdatesContext.onFullScreen,
      videoLotsOfUpdatesContext.paused,
      videoLotsOfUpdatesContext.settingsActive
    ]
  );
  return (
    <VideoLotsOfUpdatesContext.Provider value={value}>
      {props.children}
    </VideoLotsOfUpdatesContext.Provider>
  );
}

function useVideoLotsOfUpdatesContext() {
  const context = useContextSelector(VideoLotsOfUpdatesContext, (state) => state);
  if (typeof context === "undefined") {
    throw new Error("useVideoLotsOfUpdatesContext must be used within a VideoStateProvider");
  }
  return context;
}

export { VideoLotsOfUpdatesProvider, useVideoLotsOfUpdatesContext };
