import { HTMLProps, useCallback, useMemo } from "react";
import { isIOS, isMobile } from "react-device-detect";
import { useGlobalStateContext } from "../contexts/GlobalStateContext";
import { useHoverStateContext } from "../contexts/HoverStateContext";
import { useSubtitleStateContext } from "../contexts/SubtitleStateContext";
import { useVideoFewUpdatesContext } from "../contexts/VideoFewUpdatesContext";
import { useVideoLotsOfUpdatesContext } from "../contexts/VideoLotsOfUpdatesContext";
import { useVideoUpdateContext } from "../contexts/VideoUpdateContext";
import SubtitleVideoCaptionIOS from "../features/subtitles/SubtitleVideoCaption/SubtitleVideoCaptionIOS";

export interface IVideoProps extends HTMLProps<HTMLVideoElement> {
  src: string;
}

export default function useVideoProps() {
  const { updateHoverState } = useHoverStateContext();
  const {
    onTimeUpdate: onTimeUpdateCallback,
    sources,
    playerRef,
    autoPlay = true,
    startTime = 0,
  } = useGlobalStateContext();
  const { setVideoFewUpdatesContext } = useVideoFewUpdatesContext();
  const { videoLotsOfUpdatesContext, setVideoLotsOfUpdatesContext } =
    useVideoLotsOfUpdatesContext();
  const { setVideoUpdate } = useVideoUpdateContext();
  const { mediaState } = useSubtitleStateContext();
  const handleScreenClicked = useCallback(
    (e: any) => {
      if (videoLotsOfUpdatesContext.settingsActive) {
        setVideoLotsOfUpdatesContext((prevState) => ({
          ...prevState,
          settingsActive: false,
        }));
      }
      if (!videoLotsOfUpdatesContext.settingsActive && isMobile) {
        updateHoverState();
      }
      if (!videoLotsOfUpdatesContext.settingsActive && !isMobile) {
        setVideoLotsOfUpdatesContext((prevState) => ({
          ...prevState,
          paused: !prevState.paused,
        }));
      }
      if (e.detail === 2 && !isMobile) {
        setVideoLotsOfUpdatesContext((prevState) => ({
          ...prevState,
          onFullScreen: !prevState.onFullScreen,
        }));
      }
    },
    [setVideoLotsOfUpdatesContext, updateHoverState, videoLotsOfUpdatesContext.settingsActive]
  );
  const videoProps: IVideoProps = useMemo(
    () => ({
      // crossOrigin: "anonymous",
      playsInline: true,
      onClickCapture: handleScreenClicked,
      controls: false,
      autoPlay,
      src: sources[mediaState?.qualityIndex]?.url,
      onWaiting: () => {
        setVideoFewUpdatesContext((prevState) => ({ ...prevState, loading: true }));
      },
      onPlaying: () => {
        setVideoFewUpdatesContext((prevState) => ({
          ...prevState,
          loading: false,
        }));
        setVideoLotsOfUpdatesContext((prevState) => ({
          ...prevState,
          paused: false,
        }));
      },
      onLoadedData: () => {
        setVideoFewUpdatesContext((prevState) => ({
          ...prevState,
          loadedData: true,
          duration: playerRef?.current?.duration || 0,
        }));
        setVideoLotsOfUpdatesContext((prevState) => ({
          ...prevState,
          paused: !autoPlay,
        }));
        setVideoUpdate((prevState) => ({
          ...prevState,
          currentTime: 0,
        }));
        if (startTime) {
          playerRef?.current && (playerRef.current.currentTime = startTime);
        }
      },
      onTimeUpdate: (e) => {
        setVideoUpdate((prevState) => ({
          ...prevState,
          currentTime: playerRef?.current?.currentTime || 0,
        }));
        setVideoFewUpdatesContext((prevState) => ({
          ...prevState,
          duration: playerRef?.current?.duration || 0,
        }));
        if (onTimeUpdateCallback) onTimeUpdateCallback(e);
      },
      onEnded: () => {
        setVideoLotsOfUpdatesContext((prevState) => ({
          ...prevState,
          paused: true,
        }));
      },
      onMouseMove: updateHoverState,
      children: isIOS ? <SubtitleVideoCaptionIOS /> : null,
    }),
    [
      autoPlay,
      handleScreenClicked,
      mediaState?.qualityIndex,
      playerRef,
      setVideoFewUpdatesContext,
      setVideoLotsOfUpdatesContext,
      setVideoUpdate,
      sources,
      updateHoverState,
    ]
  );
  return { handleScreenClicked, videoProps, currentSource: sources[mediaState?.qualityIndex] };
}
