import { memo } from "react";

const CircularProgress = () => {
  return (
    <svg viewBox="25 25 50 50" className="w-[70px] h-[70px] embed-progress">
      <circle cx="50" cy="50" r="20"></circle>
    </svg>
  );
};

export default memo(CircularProgress);
