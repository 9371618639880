import React, { Component, ErrorInfo } from "react";

interface IErrorBoundaryProps {
  children: React.ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
  errorMessage: string;
  errorInfo: string;
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: "",
      errorInfo: ""
    };
  }
  public static getDerivedStateFromError() {
    return { hasError: true };
  }
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      ...this.state,
      errorMessage: error.message,
      errorInfo: errorInfo.componentStack
    });
    console.error("Error message: ", error.message);
    console.error("Error info: ", errorInfo);
  }
  public render() {
    const { hasError, errorMessage, errorInfo } = this.state;
    const { children } = this.props;
    if (hasError) {
      return null;
      return (
        <div className="absolute top-1/2 left-1/2 z-50 -translate-y-1/2 -translate-x-1/2 space-y-4">
          <p className="text-4xl font-semibold text-center">｡゜(｀Д´)゜｡</p>
          <p className="text-xl text-center">An error has occured</p>
          <p className="text-lg text-center">{errorMessage}</p>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
