import React, {
  ButtonHTMLAttributes,
  Dispatch,
  RefObject,
  memo,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { defaultI18n } from "../../constants/i18n";
import { useGlobalStateContext } from "../../contexts/GlobalStateContext";
import { useI18nContext } from "../../contexts/I18nContext";
import {
  IVideoFewUpdatesContext,
  useVideoFewUpdatesContext,
} from "../../contexts/VideoFewUpdatesContext";
import { IKeyBoardShortcut } from "../../types";
import IconVolumeMuted from "../../components/Icons/IconVolumeMuted";
import IconVolumeOne from "../../components/Icons/IconVolumeOne";
import IconVolumeThree from "../../components/Icons/IconVolumeThree";
import IconVolumeTwo from "../../components/Icons/IconVolumeTwo";

const VolumeComponents = {
  0: IconVolumeMuted,
  0.25: IconVolumeOne,
  0.5: IconVolumeTwo,
  1: IconVolumeThree,
};

interface ButtonVolumeMemoProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  volume: number;
  isMuted: boolean;
  setVideoFewUpdatesContext: Dispatch<React.SetStateAction<IVideoFewUpdatesContext>>;
  keyboardShortcut: boolean | IKeyBoardShortcut | undefined;
  playerRef: RefObject<HTMLVideoElement> | undefined;
}

const ButtonVolumeMemo = memo((props: ButtonVolumeMemoProps) => {
  const { keyboardShortcut, playerRef, isMuted, volume, setVideoFewUpdatesContext, ...others } =
    props;
  const volumeButtonRef = useRef<HTMLButtonElement>(null);
  const { i18n } = useI18nContext();
  const VolumeComponent = useMemo(() => {
    if (isMuted) return IconVolumeMuted;
    const entries = Object.entries(VolumeComponents).sort((a, b) => Number(a[0]) - Number(b[0]));
    for (const [key, value] of entries) {
      if (volume / 100 <= Number(key)) return value;
    }
    return IconVolumeMuted;
  }, [volume, isMuted]);
  const toggleSound = () => {
    setVideoFewUpdatesContext((prevState) => ({
      ...prevState,
      isMuted: !prevState.isMuted,
      volume: prevState.volume === 0 ? 100 : prevState.volume,
    }));
  };
  useEffect(() => {
    const keyHandler = (e: KeyboardEvent) => {
      if (!keyboardShortcut) return;
      if ((keyboardShortcut === true || keyboardShortcut.mute) && e.key === "m") {
        volumeButtonRef.current?.click();
      }
    };
    window.addEventListener("keyup", keyHandler);
    return () => {
      window.removeEventListener("keyup", keyHandler);
    };
  }, [keyboardShortcut]);
  useEffect(() => {
    if (playerRef?.current) {
      playerRef.current.muted = isMuted;
      playerRef.current.volume = isMuted ? 0 : volume / 100;
    }
    localStorage.setItem("embed-volume", String(volume));
    localStorage.setItem("embed-muted", String(+isMuted));
  }, [volume, isMuted, playerRef]);
  return (
    <button
      ref={volumeButtonRef}
      className="embed-center-container embed-scale"
      data-embed-tooltips={
        isMuted || volume === 0
          ? i18n?.tooltipsUnmute || defaultI18n.tooltipsUnmute
          : i18n?.tooltipsMute || defaultI18n.tooltipsMute
      }
      onClickCapture={toggleSound}
      {...others}
    >
      <VolumeComponent className="embed-icon-lg" />
    </button>
  );
});

ButtonVolumeMemo.displayName = "ButtonVolumeMemo";

const ButtonVolume = () => {
  const { videoFewUpdatesContext, setVideoFewUpdatesContext } = useVideoFewUpdatesContext();
  const { keyboardShortcut, playerRef } = useGlobalStateContext();
  return (
    <ButtonVolumeMemo
      className="embed-center-container embed-scale"
      volume={videoFewUpdatesContext.volume}
      isMuted={videoFewUpdatesContext.isMuted}
      setVideoFewUpdatesContext={setVideoFewUpdatesContext}
      keyboardShortcut={keyboardShortcut}
      playerRef={playerRef}
    />
  );
};

export default ButtonVolume;
