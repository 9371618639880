import React from "react";

const IconLoadingServer = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      className="absolute inset-0 w-4 h-4 animate-spin"
      fill="none"
      viewBox="0 0 66 66"
    >
      <circle
        cx="33"
        cy="33"
        fill="none"
        r="28"
        stroke="currentColor"
        strokeWidth="10"
        className="opacity-30"
      ></circle>
      <circle
        cx="33"
        cy="33"
        fill="none"
        r="28"
        stroke="currentColor"
        strokeDasharray="40, 134"
        strokeDashoffset="325"
        strokeLinecap="round"
        strokeWidth="10"
        className="opacity-70"
      ></circle>
    </svg>
  );
};

export default IconLoadingServer;
