import { MediaType } from "types";

export type MediaTypeTMDB = "movie" | "tv";

export interface IParamsSearchTMDB {
  title: string;
  language: string;
  extraData: {
    episodeId?: string;
    seasonId?: string;
    mediaType: MediaType;
    year?: number;
    imdb_id?: string;
    tmdb_id?: number;
    totalSeasons?: number;
    totalEpisodes?: number;
    [key: string]: any;
  };
}

export declare module TMDBType {
  interface IMovieDetails {
    adult: boolean;
    backdrop_path: string;
    belongs_to_collection: IBelongsToCollection;
    budget: number;
    genres: IGenre[];
    homepage: string;
    id: number;
    imdb_id: string;
    original_language: string;
    original_title: string;
    overview: string;
    popularity: number;
    poster_path: string;
    production_companies: IProductionCompany[];
    production_countries: IProductionCountry[];
    release_date: Date;
    revenue: number;
    runtime: number;
    spoken_languages: ISpokenLanguage[];
    status: string;
    title_english: string;
    title_portuguese: string;
    tagline: string;
    title: string;
    video: boolean;
    vote_average: number;
    vote_count: number;
    credits: ICredits;
    external_ids: IExternalIDS;
    translations: IDetailsTransition;
  }
  interface ISeriesDetails {
    adult: boolean;
    title_english: string;
    title_portuguese: string;
    backdrop_path: string;
    created_by: any[];
    episode_run_time: number[];
    first_air_date: Date | string;
    genres: IGenre[];
    homepage: string;
    id: number;
    in_production: boolean;
    languages: string[];
    last_air_date: Date | string;
    last_episode_to_air: any;
    name: string;
    next_episode_to_air: null;
    networks: INetwork[];
    number_of_episodes: number;
    number_of_seasons: number;
    origin_country: string[];
    original_language: string;
    original_name: string;
    overview: string;
    popularity: number;
    poster_path: string;
    production_companies: INetwork[];
    production_countries: IProductionCountry[];
    seasons: ISeason[];
    spoken_languages: ISpokenLanguage[];
    status: string;
    tagline: string;
    type: string;
    vote_average: number;
    vote_count: number;
    credits: ICredits;
    external_ids: IExternalIDS;
    translations: IDetailsTransition;
  }
  interface ICast {
    adult: boolean;
    gender: number;
    id: number;
    known_for_department: string;
    name: string;
    original_name: string;
    popularity: number;
    profile_path: null | string;
    character?: string;
    credit_id: string;
    order?: number;
    department?: string;
    job?: string;
  }
  interface IBelongsToCollection {
    id: number;
    name: string;
    poster_path: string;
    backdrop_path: string;
  }
  interface ICredits {
    cast: ICast[];
    crew: ICast[];
  }
  interface ICast {
    adult: boolean;
    gender: number;
    id: number;
    known_for_department: string;
    name: string;
    original_name: string;
    popularity: number;
    profile_path: null | string;
    cast_id?: number;
    character?: string;
    credit_id: string;
    order?: number;
    department?: string;
    job?: string;
  }
  interface IExternalIDS {
    imdb_id: string;
    wikidata_id: string;
    facebook_id: string;
    instagram_id: string;
    twitter_id: string;
  }
  interface IGenre {
    id: number;
    name: string;
  }
  interface IProductionCompany {
    id: number;
    logo_path: string;
    name: string;
    origin_country: string;
  }
  interface IProductionCountry {
    iso_3166_1: string;
    name: string;
  }
  interface ISpokenLanguage {
    english_name: string;
    iso_639_1: string;
    name: string;
  }
  interface IEpisode {
    air_date: Date;
    crew: ICast[];
    episode_number: number;
    guest_stars: ICast[];
    name: string;
    overview: string;
    id: number;
    production_code: string;
    runtime: number;
    season_number: number;
    still_path: string;
    vote_average: number;
    vote_count: number;
    external_ids: { imdb_id: string };
  }
}

export enum TMDBMediaType {
  Movie = "movie",
  Person = "person",
  Tv = "tv",
}

export interface IPhoto {
  aspect_ratio: number;
  height: number;
  iso_639_1: null | string;
  file_path: string;
  vote_average: number;
  vote_count: number;
  width: number;
}

export interface IShowTVResult {
  backdrop_path: string;
  first_air_date: Date;
  genre_ids: number[];
  id: number;
  name: string;
  origin_country: string[];
  original_language: string;
  original_name: string;
  overview: string;
  popularity: number;
  poster_path: string;
  vote_average: number;
  vote_count: number;
}

export interface IMovieResult {
  adult: boolean;
  backdrop_path: null | string;
  genre_ids: number[];
  id: number;
  original_language: string;
  original_title: string;
  overview: string;
  popularity: number;
  poster_path: string;
  release_date: Date;
  title: string;
  video: boolean;
  vote_average: number;
  vote_count: number;
}

export interface IShowTVCard {
  backdrop_path: string;
  first_air_date: Date;
  genre_ids: number[];
  id: number;
  name: string;
  origin_country: string[];
  original_language: string;
  original_name: string;
  overview: string;
  popularity: number;
  poster_path: string;
  vote_average: number;
  vote_count: number;
}

export interface IPageResult {
  page: number;
  results: any[];
  total_pages: number;
  total_results: number;
}

export interface IMovieList {
  page: number;
  results: IMovieResult[];
  total_pages: number;
  total_results: number;
}

export interface IShowTVLists {
  page: number;
  results: IShowTVResult[];
  total_pages: number;
  total_results: number;
}

export interface IMovieDetails {
  adult: boolean;
  backdrop_path: string;
  belongs_to_collection: null;
  budget: number;
  genres: IGenre[];
  homepage: string;
  id: number;
  imdb_id: string;
  original_language: string;
  original_title: string;
  overview: string;
  popularity: number;
  poster_path: string;
  production_companies: IProductionCompany[];
  production_countries: IProductionCountry[];
  release_date: Date;
  revenue: number;
  runtime: number;
  spoken_languages: ISpokenLanguage[];
  status: string;
  tagline: string;
  title: string;
  video: boolean;
  vote_average: number;
  vote_count: number;
  recommendations: IMovieList;
}

export interface ISearchResult {
  adult: boolean;
  backdrop_path?: null | string;
  id: number;
  title?: string;
  original_language?: string;
  original_title?: string;
  overview?: string;
  poster_path?: string;
  media_type: TMDBMediaType;
  genre_ids?: number[];
  popularity: number;
  release_date?: Date;
  video?: boolean;
  vote_average?: number;
  vote_count?: number;
  name?: string;
  original_name?: string;
  first_air_date?: Date;
  origin_country?: string[];
  gender?: number;
  known_for_department?: string;
  profile_path?: null;
  known_for?: ISearchResult[];
}

export interface ISeriesDetails {
  adult: boolean;
  backdrop_path: string;
  created_by: any[];
  episode_run_time: number[];
  first_air_date: Date;
  genres: IGenre[];
  homepage: string;
  id: number;
  in_production: boolean;
  languages: string[];
  last_air_date: Date;
  last_episode_to_air: ILastEpisodeToAirTMDB;
  name: string;
  next_episode_to_air: null;
  networks: INetwork[];
  number_of_episodes: number;
  number_of_seasons: number;
  origin_country: string[];
  original_language: string;
  original_name: string;
  overview: string;
  popularity: number;
  poster_path: string;
  production_companies: INetwork[];
  production_countries: IProductionCountry[];
  seasons: ISeason[];
  spoken_languages: ISpokenLanguage[];
  status: string;
  tagline: string;
  type: string;
  vote_average: number;
  vote_count: number;
  recommendations: IShowTVLists;
}

export interface ILastEpisodeToAirTMDB {
  air_date: Date;
  episode_number: number;
  id: number;
  name: string;
  overview: string;
  production_code: string;
  runtime: number;
  season_number: number;
  show_id: number;
  still_path: string;
  vote_average: number;
  vote_count: number;
}

export interface INetwork {
  id: number;
  name: string;
  logo_path: string;
  origin_country: string;
}

export interface IGenre {
  id: number;
  name: string;
}

export interface IProductionCompany {
  id: number;
  logo_path: string;
  name: string;
  origin_country: string;
}

export interface IProductionCountry {
  iso_3166_1: string;
  name: string;
}

export interface ISpokenLanguage {
  english_name: string;
  iso_639_1: string;
  name: string;
}

export interface ISeason {
  air_date: string;
  episode_count: number;
  id: number;
  name: string;
  episodes: TMDBType.IEpisode[];
  overview: string;
  poster_path: string;
  season_number: number;
}

export interface ICategory {
  id: number;
  name: string;
}

export interface IDetailsTransition {
  id: number;
  translations: {
    iso_3166_1: string;
    iso_639_1: string;
    name: string;
    english_name: string;
    data: { name: string; overview: string; homepage: string; tagline: any };
  }[];
}
