import * as CryptoJS from "crypto-js";
import { customAlphabet } from "nanoid";
import { ISources, ISubtitles } from "./types";
import { ISubtitle } from "player";
import { PATH_API } from "../../../constants/index";

const nanoid = customAlphabet("0123456789abcdef", 32);
const iv = atob("d0VpcGhUbiE=");
const key = atob("MTIzZDZjZWRmNjI2ZHk1NDIzM2FhMXc2");
const apiUrls = [
  atob("aHR0cHM6Ly9zaG93Ym94LnNoZWd1Lm5ldC9hcGkvYXBpX2NsaWVudC9pbmRleC8="),
  atob("aHR0cHM6Ly9tYnBhcGkuc2hlZ3UubmV0L2FwaS9hcGlfY2xpZW50L2luZGV4Lw=="),
];
const appKey = atob("bW92aWVib3g=");
const appId = atob("Y29tLnRkby5zaG93Ym94");

const crypto = {
  encrypt(str: string) {
    return CryptoJS.TripleDES.encrypt(str, CryptoJS.enc.Utf8.parse(key), {
      iv: CryptoJS.enc.Utf8.parse(iv),
    }).toString();
  },
  getVerify(str: string, str2: string, str3: string) {
    if (str) {
      return CryptoJS.MD5(CryptoJS.MD5(str2).toString() + str3 + str).toString();
    }
    return null;
  },
};

export const expiry = () => Math.floor(Date.now() / 1000 + 60 * 60 * 12);

export const get = async (data: object, altApi = false) => {
  const defaultData = {
    childmode: "0",
    app_version: "11.5",
    appid: appId,
    lang: "en",
    expired_date: `${expiry()}`,
    platform: "android",
    channel: "Website",
  };
  const encryptedData = crypto.encrypt(
    JSON.stringify({
      ...defaultData,
      ...data,
    })
  );
  const appKeyHash = CryptoJS.MD5(appKey).toString();
  const verify = crypto.getVerify(encryptedData, appKey, key);
  const body = JSON.stringify({
    app_key: appKeyHash,
    verify,
    encrypt_data: encryptedData,
  });
  const b64Body = btoa(body);
  const formatted = new URLSearchParams();
  formatted.append("data", b64Body);
  formatted.append("appid", "27");
  formatted.append("platform", "android");
  formatted.append("version", "129");
  formatted.append("medium", "Website");
  formatted.append("token", nanoid());
  const requestUrl = altApi ? apiUrls[1] : apiUrls[0];
  const cacheKey = `${requestUrl}${formatted.toString()}&token${nanoid()}`;
  const cachedResponse = await caches.match(cacheKey);
  if (cachedResponse) {
    return cachedResponse;
  }
  const response = await fetch(`${PATH_API.BYPASS_CORS_WORKERS(requestUrl)}`, {
    method: "POST",
    headers: {
      Platform: "android",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `${formatted.toString()}`,
  });
  const cache = await caches.open("api-cache");
  cache.put(cacheKey, response.clone());
  return response;
};

export const sortSourceSuperstream = (mediaList: ISources[]) => {
  const sourcesQuality: string[] = [];
  return (
    //  source.real_quality !== "4K" &&
    mediaList
      ?.filter((source: any) => source.path)
      .map((item: any, index: number) => {
        const quality = sourcesQuality.includes(item.real_quality)
          ? `${item.real_quality} #${index}`
          : `${item.real_quality}`;
        sourcesQuality.push(item.real_quality);
        return { type: "mp4", url: item.path, quality: "#S: " + quality };
      }) || []
  );
};

export const sortSubtitlesSuperstream = (data: ISubtitles[]): ISubtitle[] => {
  return (
    data?.map((subtitle) => {
      const foundIndex = subtitle.subtitles.findIndex((sub) => sub.delay === 0);
      return {
        server: "superstream",
        lang: subtitle.language,
        language: subtitle.language,
        url: `${subtitle.subtitles[foundIndex]?.file_path}`,
      };
    }) || []
  );
};
