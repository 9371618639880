export enum FlixhqType {
  TVSERIES = "TV Series",
  MOVIE = "Movie",
  ANIME = "Anime",
}

export interface IMediaSource {
  headers: { Referer: string };
  subtitles: ISubtitle[];
  sources: ISource[];
}

export interface IStreamingServer {
  name: string;
  url: string;
}

export interface ISearchResult {
  id: string;
  title: string;
  url: string;
  seasons?: number;
  releaseDate?: string;
  type: FlixhqType;
}

export interface IRecommendation {
  id: string;
  title: string;
  image: string;
  duration: string;
  type: MediaTypeFlixhq;
}

export interface IEpisode {
  id: string;
  title: string;
  number: number;
  season: number;
  url: string;
}

export interface IDetailsInfo {
  id: string;
  title: string;
  url: string;
  cover: string;
  image: string;
  description: string;
  type: string;
  releaseDate: Date;
  genres: string[];
  casts: string[];
  tags: string[];
  production: string;
  country: string;
  duration: string;
  rating: number;
  recommendations: IRecommendation[];
  episodes: ITvEpisode[];
}

export interface IMovieDetails {
  id: string;
  title: string;
  url: string;
  cover: string;
  image: string;
  description: string;
  type: string;
  releaseDate: Date;
  genres: string[];
  casts: string[];
  tags: string[];
  production: string;
  country: string;
  duration: string;
  rating: number;
  recommendations: IRecommendation[];
  episodes: IEpisode[];
}

export interface ISource {
  url: string;
  quality: string;
  isM3U8: boolean;
}

export interface ISubtitle {
  url: string;
  lang: string;
}

export interface ITVDetails {
  id: string;
  title: string;
  url: string;
  cover: string;
  image: string;
  description: string;
  type: string;
  releaseDate: Date;
  genres: string[];
  casts: string[];
  tags: string[];
  production: string;
  country: string;
  duration: string;
  rating: number;
  recommendations: IRecommendation[];
  episodes: ITvEpisode[];
}

export interface ITvEpisode {
  id: string;
  title: string;
  number: number;
  season: number;
  url: string;
}

export enum MediaTypeFlixhq {
  MOVIE = "Movie",
  TVSERIES = "TV Series",
}

export enum StreamingServersFlixhq {
  UpCloud = "upcloud",
  VidCloud = "vidcloud",
  MixDrop = "mixdrop",
}
