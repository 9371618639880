import ChevronLeft from "../../components/Icons/ChevronLeft";
import { SettingSections, useSettingsContext } from "../../contexts/SettingsContext";

interface SettingMenuHeaderProps {
  title: string;
  backSectionName?: SettingSections;
}

const SettingMenuHeader = ({
  title,
  backSectionName = SettingSections.main
}: SettingMenuHeaderProps) => {
  const { setActiveSection } = useSettingsContext();
  return (
    <div
      onClick={() => setActiveSection(backSectionName)}
      className="flex items-center cursor-pointer px-4 gap-4 min-h-[64px] text-lg border-b border-b-[#565552]"
    >
      <div className="flex items-center justify-center w-8 h-8">
        <ChevronLeft className="w-[18px] h-[18px]" />
      </div>
      <span>{title}</span>
    </div>
  );
};

export default SettingMenuHeader;
