import { MediaType } from "types";
import axios from "axios";
import { load } from "cheerio";
import { IMediaSource, IParamsMediaSource } from "../types";
import { baseUrlVisioncine } from "./constants";
import { search } from "./search";
import { getSourcesWebsocket } from "./utils";
import { PATH_API } from "../../../constants";

export const getMediaSourcesVisioncine = async ({
  title,
  extraData,
}: IParamsMediaSource): Promise<IMediaSource> => {
  const searchResult = await search({ title, extraData });
  if (!searchResult) throw new Error("Not found movie");
  if (extraData.mediaType === MediaType.MOVIE) {
    const { data: detailsHTML } = await axios.get(PATH_API.BYPASS_CORS_WORKERS(searchResult));
    const $ = load(detailsHTML);
    const servers: { label: string; url: string }[] = [];
    $(".infoPoster .info .buttons a").each((_, ele) => {
      servers.push({ url: $(ele).attr("href")!, label: $(ele).text() });
    });
    const { data: detailsEmbed } = await axios.get<string>(
      PATH_API.BYPASS_CORS_WORKERS(servers[0].url)
    );
    if (detailsEmbed.includes("getUrlFromYoucine('youcine://")) {
      const mediaSources = await getSourcesWebsocket(detailsEmbed);
      return mediaSources;
    } else {
      const indexOfStartMp4 = detailsEmbed.indexOf("initializePlayer('");
      const mp4Url = detailsEmbed.slice(
        indexOfStartMp4 + 18,
        detailsEmbed.indexOf("',", indexOfStartMp4)
      );
      return { sources: [{ quality: "HD", url: mp4Url }], subtitles: [] };
    }
  }
  const { data: detailsHTML } = await axios.get(PATH_API.BYPASS_CORS_WORKERS(searchResult));
  const $ = load(detailsHTML);
  const episodes: string[] = [];
  const seasonId = Number(extraData.seasonId || 1);
  const episodeId = Number(extraData.episodeId || 1);
  if (seasonId === 1) {
    $("#episodes-view .ep > .buttons a").each((index, ele) => {
      episodes.push($(ele).attr("href")!);
    });
  } else {
    const seasons: string[] = [];
    $("#seasons-view option").each((index, ele) => {
      seasons.push($(ele).attr("value")!);
    });
    const { data: episodesHTML } = await axios.get(
      PATH_API.BYPASS_CORS_WORKERS(
        `${baseUrlVisioncine}/ajax/episodes.php?season=${seasons[seasonId - 1]}`
      )
    );
    const $$ = load(episodesHTML);
    $$(".ep > .buttons a").each((index, ele) => {
      episodes.push($$(ele).attr("href")?.replace(">", "")!);
    });
  }
  const foundEpisode = episodes[episodeId - 1];
  let { data: detailsEmbed } = await axios.get<string>(PATH_API.BYPASS_CORS_WORKERS(foundEpisode));
  const $$$ = load(detailsEmbed);
  const servers: { label: string; url: string }[] = [];
  $$$(".dropdown-menu-right a").each((_, ele) => {
    servers.push({ label: $(ele).text(), url: $(ele).attr("href")! });
  });
  try {
    const mediaSources = await getSourcesWebsocket(detailsEmbed);
    return mediaSources;
  } catch (error) {
    console.log("error: ", error);
    const { data: newDetailsEmbed } = await axios.get<string>(
      PATH_API.BYPASS_CORS_WORKERS(servers[1].url)
    );
    const mediaSources = await getSourcesWebsocket(newDetailsEmbed);
    return mediaSources;
  }
};
