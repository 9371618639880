import classNames from "../../../utils/classNames";
import { memo } from "react";
import { isMobile } from "react-device-detect";
import { useGlobalStateContext } from "../../contexts/GlobalStateContext";
import { useHoverStateContext } from "../../contexts/HoverStateContext";
import ButtonBackward from "../buttons/ButtonBackward";
import ButtonForward from "../buttons/ButtonForward";
import ButtonFullscreen from "../buttons/ButtonFullscreen";
import ButtonPlay from "../buttons/ButtonPlay";
import ButtonVolume from "../buttons/ButtonVolume";
import ClickAwayListener from "../core/ClickAwayListener";
import VideoSeekBar from "./VideoSeekBar";
import VideoTimeIndicator from "./VideoTimeIndicator";
import VideoVolumeSlider from "./VideoVolumeSlider";

interface VideoControlsMemoProps {
  isHoverEnabled: boolean;
  heading: string;
  handleClearTimeout: () => void;
  updateHoverState: () => void;
  rightControlsComponent?: React.ReactNode;
}

const VideoControlsMemo = memo(
  ({
    heading,
    rightControlsComponent,
    handleClearTimeout,
    isHoverEnabled,
    updateHoverState,
  }: VideoControlsMemoProps) => {
    return (
      <div
        onTouchEnd={updateHoverState}
        onClick={updateHoverState}
        onMouseEnter={handleClearTimeout}
        className={classNames("embed-controls", isHoverEnabled && "embed-show")}
      >
        <VideoSeekBar />
        <div className="embed-controls-main">
          <div className="embed-controls-section">
            {!isMobile ? (
              <>
                <ButtonPlay />
                <ButtonBackward />
                <ButtonForward />
              </>
            ) : null}
            <div className="embed-volume-container">
              <ButtonVolume />
              <VideoVolumeSlider />
            </div>
            {isMobile ? <VideoTimeIndicator /> : null}
          </div>
          {isMobile ? null : (
            <div className="absolute text-xl font-semibold -translate-x-1/2 -translate-y-1/2 line-clamp-1 top-1/2 left-1/2 drop-shadow-md shadow-black">
              {heading}
            </div>
          )}
          <div className="embed-controls-section">
            {rightControlsComponent}
            <ClickAwayListener />
            <ButtonFullscreen />
          </div>
        </div>
      </div>
    );
  }
);

VideoControlsMemo.displayName = "VideoControlsMemo";

interface VideoControlsProps {
  rightControlsComponent?: React.ReactNode;
}

const VideoControls = ({ rightControlsComponent }: VideoControlsProps) => {
  const { heading } = useGlobalStateContext();
  const { handleClearTimeout, isHoverEnabled, updateHoverState } = useHoverStateContext();
  return (
    <VideoControlsMemo
      heading={heading}
      rightControlsComponent={rightControlsComponent}
      handleClearTimeout={handleClearTimeout}
      isHoverEnabled={isHoverEnabled}
      updateHoverState={updateHoverState}
    />
  );
};

export default VideoControls;
