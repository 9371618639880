import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import storage from "../constants/localStorage";
import { ISubtitle } from "../types";
import { useGlobalStateContext } from "./GlobalStateContext";
import { emptySubtitle } from "../constants";
import { getQueryDataOpenSubtitles } from "../../backend/opensubtitles/useOpenSubtitles";

export interface ISubtitleSettings {
  fontSize: number;
  backgroundOpacity: number;
  color: string;
}

export interface ISubtitleState {
  qualityIndex: number;
  latency: string;
  subtitleSelected: ISubtitle;
  subtitleLangNameSelected: string;
  subtitleSelectedUrl: string;
  subtitleSettings: ISubtitleSettings;
}

interface MediaStateProviderProps {
  children: React.ReactNode;
}

export interface ISubtitleStateContext {
  mediaState: ISubtitleState;
  setMediaState: React.Dispatch<React.SetStateAction<ISubtitleState>>;
}

const defaultMediaState: ISubtitleState = {
  qualityIndex: 0,
  latency: "0",
  subtitleLangNameSelected: "English",
  subtitleSelected: emptySubtitle,
  subtitleSelectedUrl: "",
  subtitleSettings: storage.getSubtitleSettings(),
};

const SubtitleStateContext = createContext<ISubtitleStateContext>({
  mediaState: defaultMediaState,
  setMediaState: () => {},
});

function MediaStateProvider(props: MediaStateProviderProps) {
  const { subtitles, meta } = useGlobalStateContext();
  const subtitlesOpensubtitles = getQueryDataOpenSubtitles({
    imdb_id: meta?.imdb_id as string,
  });
  const getSubtitleSelected = useCallback(() => {
    return (
      subtitles?.[0] ||
      subtitlesOpensubtitles?.find(
        (subs) => subs.languageName === defaultMediaState.subtitleLangNameSelected
      )?.subtitles?.[0] ||
      emptySubtitle
    );
  }, [subtitles, subtitlesOpensubtitles]);
  const [mediaState, setMediaState] = useState({
    qualityIndex: 0,
    subtitleLangNameSelected: "English",
    subtitleSelected: getSubtitleSelected(),
    subtitleSelectedUrl: "",
    latency: "0",
    subtitleSettings: storage.getSubtitleSettings(),
  });
  useEffect(() => {
    setMediaState((prevState) => ({ ...prevState, subtitleSelected: getSubtitleSelected() }));
  }, [getSubtitleSelected, subtitles]);
  const value = useMemo(
    () => ({ mediaState, setMediaState }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      mediaState.qualityIndex,
      mediaState.subtitleLangNameSelected,
      mediaState.subtitleSelectedUrl,
      mediaState.subtitleSelected,
      mediaState.subtitleSettings,
      mediaState.latency,
    ]
  );
  return (
    <SubtitleStateContext.Provider value={value}>{props.children}</SubtitleStateContext.Provider>
  );
}

function useSubtitleStateContext() {
  const context = useContextSelector(SubtitleStateContext, (state) => state);
  if (typeof context === "undefined") {
    throw new Error("useSubtitleStateContext must be used within a MediaStateProvider");
  }
  return context;
}

export { MediaStateProvider, useSubtitleStateContext };
