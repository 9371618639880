import axios from "axios";
import { baseUrlLoveflix } from "./constants";
import { load } from "cheerio";
import { PATH_API } from "../../../constants";

export const getMp4fromEmbed = async (urlWatchPage: string) => {
  const { data: htmlDetails } = await axios(PATH_API.BYPASS_CORS_WORKERS(urlWatchPage));
  const $ = load(htmlDetails);
  const idEmbed = $(".nav-player-select .dropdown-toggle.btn-service.selected").attr("data-embed");
  const { data: dataRequestEmbed } = await axios.post(
    PATH_API.BYPASS_CORS_WORKERS(`${baseUrlLoveflix}/ajax/embed`),
    {
      id: idEmbed,
    },
    {
      headers: {
        "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        "x-requested-with": "XMLHttpRequest",
      },
    }
  );
  const $$ = load(dataRequestEmbed);
  const urlEmbed = $$("iframe").attr("src")!;
  const { data: htmlEmbed } = await axios(PATH_API.BYPASS_CORS_WORKERS(urlEmbed));
  const indexOfStartUrlMp4 = htmlEmbed.indexOf("file: '");
  const urlMp4 = htmlEmbed.slice(
    indexOfStartUrlMp4 + 7,
    htmlEmbed.indexOf("'}"),
    indexOfStartUrlMp4 + 7
  );
  return urlMp4;
};
