export default function classNames(...args: any[]) {
  return args
    .flatMap((arg) => {
      if (!arg) return [];
      if (typeof arg === "string") return arg.split(" ");
      if (Array.isArray(arg)) return arg;
      return Object.values(arg);
    })
    .join(" ");
}
