import { defaultI18n } from "../../constants/i18n";
import { useI18nContext } from "../../contexts/I18nContext";
import { SettingSections, useSettingsContext } from "../../contexts/SettingsContext";
import { useSubtitleStateContext } from "../../contexts/SubtitleStateContext";
import SettingMenuHeader from "../settings/SettingMenuHeader";
import SettingMenuChecked from "./SettingMenuChecked";

const settingsFontsize = [0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5];

const SettingSubtitleFontSize = () => {
  const { i18n } = useI18nContext();
  const { mediaState, setMediaState } = useSubtitleStateContext();
  const { sections, activeSection, setActiveSection } = useSettingsContext();
  return (
    <div
      ref={sections[SettingSections.subtitleFontsize]}
      className={`embed-settings-section ${
        activeSection === SettingSections.subtitleFontsize
          ? "embed-settings-translate-center"
          : "embed-settings-translate-right"
      }`}
    >
      <SettingMenuHeader
        title={i18n?.settingsFontSize || defaultI18n.settingsFontSize}
        backSectionName={SettingSections.subtitleCustomMethods}
      />
      <div className="embed-settings-py">
        {settingsFontsize.map((fontSize) => (
          <SettingMenuChecked
            key={fontSize}
            handleOnClick={() => {
              setMediaState((prevState) => ({
                ...prevState,
                subtitleSettings: { ...prevState.subtitleSettings, fontSize },
              }));
            }}
            settingSection={SettingSections.subtitleCustomMethods}
            isActive={mediaState.subtitleSettings.fontSize === fontSize}
            value={`${Math.floor(fontSize * 100)}%`}
          />
        ))}
      </div>
    </div>
  );
};

export default SettingSubtitleFontSize;
