import classNames from "../../../../utils/classNames";
import { parse } from "@plussub/srt-vtt-parser";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isIOS, isMobile } from "react-device-detect";
import { useHoverStateContext } from "../../../contexts/HoverStateContext";
import { useSubtitleStateContext } from "../../../contexts/SubtitleStateContext";
import { useVideoUpdateContext } from "../../../contexts/VideoUpdateContext";
import useTextScaling from "../../../hooks/useTextScaling";
import { getSubtitleText } from "./getSubtitleText";

const BASE_FONT_SIZE = 18;
const LINE_HEIGHT_RATIO = 1.333;

const SubtitleVideoCaption = () => {
  const { mediaState } = useSubtitleStateContext();
  const { isHoverEnabled } = useHoverStateContext();
  const { moderateScale } = useTextScaling();
  const [subtitleHTML, setSubtitleHTML] = useState<string>("");
  const [subtitleText, setSubtitleText] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const { videoUpdate } = useVideoUpdateContext();

  const fontSize = useMemo(() => {
    return moderateScale(mediaState.subtitleSettings.fontSize * BASE_FONT_SIZE);
  }, [moderateScale, mediaState.subtitleSettings.fontSize]);
  const lineHeight = useMemo(() => {
    return fontSize * LINE_HEIGHT_RATIO;
  }, [fontSize]);

  const getSubtitle = useCallback(async () => {
    try {
      if (!mediaState.subtitleSelected.url) return;
      setIsLoading(true);
      const text = await getSubtitleText(mediaState.subtitleSelected.url);
      setIsLoading(false);
      if (!text) return;
      setSubtitleText(text);
    } catch (error) {
      console.log("error: ", error);
    }
  }, [mediaState.subtitleSelected.url]);

  useEffect(() => {
    if (!mediaState.subtitleSelected.url) return;
    getSubtitle();
  }, [getSubtitle, mediaState.subtitleSelected.url]);

  useEffect(() => {
    try {
      if (isIOS || !subtitleText) return;
      const { entries = [] } = parse(subtitleText);
      const currentTime = videoUpdate.currentTime * 1000;
      const currentEntry = entries.find(
        (entry: any) =>
          entry.from <= currentTime + parseInt(mediaState.latency) * -1000 &&
          entry.to >= currentTime + parseInt(mediaState.latency) * -1000
      );
      setSubtitleHTML(currentEntry?.text || "");
    } catch (error) {
      console.log("error: ", error);
      setSubtitleHTML("");
    }
  }, [mediaState.latency, subtitleText, videoUpdate.currentTime]);

  useEffect(() => {
    localStorage.setItem("embed-settings-subtitle", JSON.stringify(mediaState.subtitleSettings));
  }, [mediaState.subtitleSettings]);
  if (isLoading || !mediaState.subtitleSelected.url || !subtitleHTML) {
    return null;
  }
  return (
    <div
      className={classNames(
        "embed-subtitle-container",
        isHoverEnabled && !isMobile && "interacting"
      )}
    >
      <p
        className="embed-subtitle-text"
        style={{
          color: mediaState.subtitleSettings.color,
          fontSize: fontSize + "px",
          lineHeight: lineHeight + "px",
          backgroundColor: `rgba(0, 0, 0, ${mediaState.subtitleSettings.backgroundOpacity})`,
        }}
        dangerouslySetInnerHTML={{ __html: subtitleHTML }}
      ></p>
    </div>
  );
};

export default SubtitleVideoCaption;
