import { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { PLAYER_CONTAINER_CLASS } from "../../constants";
import { useGlobalStateContext } from "../../contexts/GlobalStateContext";
import { useHoverStateContext } from "../../contexts/HoverStateContext";
import { useVideoFewUpdatesContext } from "../../contexts/VideoFewUpdatesContext";
import { useVideoLotsOfUpdatesContext } from "../../contexts/VideoLotsOfUpdatesContext";
import { useEffectUpdate } from "../../hooks/useEffectUpdate";
import SubtitleVideoCaption from "../subtitles/SubtitleVideoCaption";
import DesktopCenterControls from "../videos/DesktopCenterControls";
import MobileCenterControls from "../videos/MobileCenterControls";
import VideoBase from "../videos/VideoBase";
import VideoControls from "../videos/VideoControls";

const PlayerMain = () => {
  const {
    keyboardShortcut = true,
    poster,
    topHeaderComponent,
    rightControlsComponent,
    ...props
  } = useGlobalStateContext();
  const { videoFewUpdatesContext } = useVideoFewUpdatesContext();
  const { videoLotsOfUpdatesContext, setVideoLotsOfUpdatesContext } =
    useVideoLotsOfUpdatesContext();
  const { updateHoverState } = useHoverStateContext();
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const keyHandler = (e: KeyboardEvent) => {
      if (!keyboardShortcut) return;
      if (e.key === " " && containerRef.current?.contains(document.activeElement)) {
        (document.activeElement as any)?.blur();
      }
    };
    const spacePressHandler = (e: KeyboardEvent) => {
      if (keyboardShortcut && e.key === " ") e.preventDefault();
    };
    window.addEventListener("keyup", keyHandler);
    window.addEventListener("keydown", spacePressHandler);
    return () => {
      window.removeEventListener("keyup", keyHandler);
      window.removeEventListener("keydown", spacePressHandler);
    };
  }, [keyboardShortcut]);
  // useEffect(() => {
  //   setVideoFewUpdatesContext((prevState) => ({
  //     ...prevState,
  //     duration: 0,
  //     loading: true
  //   }));
  //   setVideoUpdate((prevState) => ({
  //     ...prevState,
  //     currentTime: 0
  //   }));
  // }, [sources, setVideoUpdate, setVideoFewUpdatesContext]);
  useEffectUpdate(() => {
    updateHoverState();
    if (videoLotsOfUpdatesContext.paused) {
      props?.playerRef?.current?.pause();
    } else {
      props?.playerRef?.current?.play().catch(() => {
        setVideoLotsOfUpdatesContext((prevState) => ({
          ...prevState,
          paused: true,
        }));
      });
    }
  }, [videoLotsOfUpdatesContext.paused]);
  return (
    <div ref={containerRef} className={PLAYER_CONTAINER_CLASS}>
      {poster && !videoFewUpdatesContext.loadedData && (
        <div className="absolute inset-0">
          <picture>
            <img src={poster} className="object-cover w-full h-full" alt="Embed Poster" />
          </picture>
          <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        </div>
      )}
      {topHeaderComponent}
      {isMobile ? <MobileCenterControls /> : <DesktopCenterControls />}
      <SubtitleVideoCaption />
      <VideoBase />
      <VideoControls rightControlsComponent={rightControlsComponent} />
    </div>
  );
};

export default PlayerMain;
