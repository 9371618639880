import { ButtonHTMLAttributes, Dispatch, memo, useEffect, useRef } from "react";
import { defaultI18n } from "../../constants/i18n";
import { useGlobalStateContext } from "../../contexts/GlobalStateContext";
import { useI18nContext } from "../../contexts/I18nContext";
import {
  IVideoLotsOfUpdates,
  useVideoLotsOfUpdatesContext,
} from "../../contexts/VideoLotsOfUpdatesContext";
import { IVideoUpdate, useVideoUpdateContext } from "../../contexts/VideoUpdateContext";
import { IKeyBoardShortcut, ISource } from "../../types";
import IconPause from "../../components/Icons/IconPause";
import IconPlay from "../../components/Icons/IconPlay";
import {
  IVideoFewUpdatesContext,
  useVideoFewUpdatesContext,
} from "../../contexts/VideoFewUpdatesContext";

interface ButtonPlayMemoProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isPaused: boolean;
  sources: ISource[];
  setVideoUpdate: Dispatch<React.SetStateAction<IVideoUpdate>>;
  setVideoLotsOfUpdatesContext: Dispatch<React.SetStateAction<IVideoLotsOfUpdates>>;
  setVideoFewUpdatesContext: Dispatch<React.SetStateAction<IVideoFewUpdatesContext>>;
  keyboardShortcut: boolean | IKeyBoardShortcut | undefined;
}

const ButtonPlayMemo = memo((props: ButtonPlayMemoProps) => {
  const {
    setVideoLotsOfUpdatesContext,
    setVideoUpdate,
    setVideoFewUpdatesContext,
    sources,
    isPaused,
    keyboardShortcut,
    ...others
  } = props;
  const pauseButton = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const keyHandler = (e: KeyboardEvent) => {
      if (!keyboardShortcut) return;
      if (
        (keyboardShortcut === true || keyboardShortcut.pause) &&
        (e.key === " " || e.key === "k")
      ) {
        pauseButton.current?.click();
      }
    };
    window.addEventListener("keyup", keyHandler);
    return () => {
      window.removeEventListener("keyup", keyHandler);
    };
  }, [keyboardShortcut]);
  // useEffect(() => {
  //   setVideoFewUpdatesContext((prevState) => ({
  //     ...prevState,
  //     duration: 0,
  //     loading: true
  //   }));
  //   setVideoUpdate((prevState) => ({
  //     ...prevState,
  //     currentTime: 0
  //   }));
  // }, [sources, setVideoUpdate, setVideoFewUpdatesContext]);
  const handleClickCapture = () => {
    setVideoLotsOfUpdatesContext((prevState) => ({
      ...prevState,
      paused: !prevState.paused,
    }));
    setVideoUpdate((prevState) => ({
      ...prevState,
      hoverEnabled: !prevState.hoverEnabled,
    }));
  };
  return (
    <button onClickCapture={handleClickCapture} {...others}>
      {isPaused ? <IconPlay className="embed-icon-lg" /> : <IconPause className="embed-icon-lg" />}
    </button>
  );
});

ButtonPlayMemo.displayName = "ButtonPlayMemo";

const ButtonPlay = () => {
  const { setVideoUpdate } = useVideoUpdateContext();
  const { keyboardShortcut, sources } = useGlobalStateContext();
  const { videoLotsOfUpdatesContext, setVideoLotsOfUpdatesContext } =
    useVideoLotsOfUpdatesContext();
  const { setVideoFewUpdatesContext } = useVideoFewUpdatesContext();
  const { i18n } = useI18nContext();
  return (
    <ButtonPlayMemo
      isPaused={videoLotsOfUpdatesContext.paused}
      data-embed-tooltips={
        videoLotsOfUpdatesContext.paused
          ? i18n?.tooltipsPlay || defaultI18n.tooltipsPlay
          : i18n?.tooltipsPause || defaultI18n.tooltipsPause
      }
      sources={sources}
      keyboardShortcut={keyboardShortcut}
      setVideoUpdate={setVideoUpdate}
      setVideoLotsOfUpdatesContext={setVideoLotsOfUpdatesContext}
      setVideoFewUpdatesContext={setVideoFewUpdatesContext}
      className="embed-center-container embed-tooltips-left embed-scale"
    />
  );
};

export default ButtonPlay;
