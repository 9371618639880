import { isMobile } from "react-device-detect";
import { useVideoUpdateContext } from "../../contexts/VideoUpdateContext";
import { formatVideoTime } from "../../utils/helper";
import { useVideoFewUpdatesContext } from "../../contexts/VideoFewUpdatesContext";

const TimeIndicator = () => {
  const { videoFewUpdatesContext } = useVideoFewUpdatesContext();
  const { videoUpdate } = useVideoUpdateContext();
  if (isMobile) {
    return (
      <div className="embed-time-indicator">
        {formatVideoTime(videoUpdate.currentTime)}
        {" / "}
        {formatVideoTime(videoFewUpdatesContext.duration)}
      </div>
    );
  }
  return <div className="embed-time-indicator">{formatVideoTime(videoUpdate.currentTime)}</div>;
};

export default TimeIndicator;
