import Spinner from "./Spinner";

const LoadingMovieScreen = () => {
  return (
    <div className="z-50 flex items-center justify-center w-full h-screen bg-black">
      <div className="flex flex-col items-center gap-3">
        <Spinner />
        <span>DONFLIX DESEJA-TE UMA BOA SESSÃO DE CINEMA.</span>
      </div>
    </div>
  );
};

export default LoadingMovieScreen;
