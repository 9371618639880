import classNames from "../../../utils/classNames";
import { defaultI18n } from "../../constants/i18n";
import { useGlobalStateContext } from "../../contexts/GlobalStateContext";
import { useI18nContext } from "../../contexts/I18nContext";
import { SettingSections, useSettingsContext } from "../../contexts/SettingsContext";
import { useSubtitleStateContext } from "../../contexts/SubtitleStateContext";
import SettingMenuHeader from "../settings/SettingMenuHeader";
import SettingMenuChecked from "./SettingMenuChecked";

const SettingQualities = () => {
  const { sources } = useGlobalStateContext();
  const { i18n } = useI18nContext();
  const { mediaState, setMediaState } = useSubtitleStateContext();
  const { sections, activeSection } = useSettingsContext();
  const activeClass =
    activeSection === SettingSections.qualities
      ? "embed-settings-translate-center"
      : "embed-settings-translate-right";
  return (
    <div
      className={classNames(`embed-settings-section`, activeClass)}
      ref={sections[SettingSections.qualities]}
    >
      <SettingMenuHeader
        title={i18n?.settingsQuality || defaultI18n.settingsQuality}
        backSectionName={SettingSections.main}
      />
      <div className="embed-settings-py">
        {sources.map((source, index) => (
          <SettingMenuChecked
            key={`${source.quality} ${index}`}
            handleOnClick={() => {
              setMediaState((prevState) => ({
                ...prevState,
                qualityIndex: index,
              }));
            }}
            settingSection={SettingSections.main}
            isActive={mediaState.qualityIndex === index}
            value={source.quality}
          />
        ))}
      </div>
    </div>
  );
};

export default SettingQualities;
