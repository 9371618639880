import { compareTwoStrings } from "utils/helper";
import axios from "axios";
import { load } from "cheerio";
import { MediaType } from "types";
import { baseUrlVisioncine } from "./constants";
import { IParamsMediaSource } from "../types";
import { PATH_API } from "../../../constants";

interface ISearchResult {
  type: MediaType;
  title: string;
  url: string;
  year: number;
}

export const search = async ({ extraData }: IParamsMediaSource): Promise<string> => {
  let title = extraData.titlePortuguese;
  const { data: html } = await axios(
    PATH_API.BYPASS_CORS_WORKERS(`${baseUrlVisioncine}/search.php?q=${encodeURIComponent(title)}`)
  );
  const $ = load(html);
  let searchResults: ISearchResult[] = [];
  $(".itemsCarousel .items .poster").each((index, movieEle) => {
    searchResults.push({
      type: $(movieEle).find(".tags span").first().text().includes("Temporadas")
        ? MediaType.TV
        : MediaType.MOVIE,
      title: $(movieEle).find("h6").text().trim()!,
      url: $(movieEle).find("a").attr("href")!,
      year: Number($(movieEle).find(".tags span:nth-child(2)").text()),
    });
  });
  if (searchResults.length === 0) return "";
  // Sort in descending order
  searchResults.sort((a: ISearchResult, b: ISearchResult) => {
    const firstRating = compareTwoStrings(title, a.title);
    const secondRating = compareTwoStrings(title, b.title);
    return secondRating - firstRating;
  });
  if (extraData && extraData.year) {
    searchResults = searchResults.filter((result: ISearchResult) => {
      return result.year === extraData.year;
    });
  }
  searchResults = searchResults.filter((result) => {
    return result.type === extraData.mediaType;
  });
  return searchResults?.[0]?.url || "";
};
