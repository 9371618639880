import { allServers } from "backend/movie-providers/main-providers";
import { IMediaServers, IServer } from "backend/movie-providers/types";
import { create } from "zustand";

interface IVideoServers {
  activeServer: string;
  setActiveServer: (server: string) => void;
  servers: IMediaServers;
  setServers: (server: IServer[]) => void;
}

const useVideoServersStore = create<IVideoServers>((set) => ({
  activeServer: allServers[0].name,
  servers: [...allServers],
  setActiveServer: (server) => set({ activeServer: server }),
  setServers: (servers) => set({ servers }),
}));

export default useVideoServersStore;
