import { TMDBType } from "backend/tmdb/types";

// https://stackoverflow.com/questions/316781/how-to-build-query-string-with-javascript
export const createQueryString = (queryConfigs: Record<string, string | number>) => {
  const esc = encodeURIComponent;
  return Object.keys(queryConfigs)
    .map((k) => esc(k) + "=" + esc(queryConfigs[k]))
    .join("&");
};

export const randomString = (length = 16) => {
  const hexCharacters = "0123456789abcdef";
  let deviceid = "";
  for (let i = 0; i < length; ++i) {
    deviceid += hexCharacters.charAt(Math.floor(Math.random() * 16));
  }
  return deviceid;
};

export const normalizeTitle = (title: string) => {
  return title
    .trim()
    .toLowerCase()
    .replace(/[\'\"\:\.]/g, "")
    .replace(/[^a-zA-Z0-9]+/g, "_");
};

export function compareTitle(a: string, b: string): boolean {
  return normalizeTitle(a) === normalizeTitle(b);
}

// https://github.com/consumet/consumet.ts/blob/master/src/utils/utils.ts
export const compareTwoStrings = (first: string, second: string): number => {
  first = normalizeTitle(first);
  second = normalizeTitle(second);
  if (first === second) return 1; // identical or empty
  if (first.length < 2 || second.length < 2) return 0; // if either is a 0-letter or 1-letter string
  const firstBigrams = new Map();
  for (let i = 0; i < first.length - 1; i++) {
    const bigram = first.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;
    firstBigrams.set(bigram, count);
  }
  let intersectionSize = 0;
  for (let i = 0; i < second.length - 1; i++) {
    const bigram = second.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;
    if (count > 0) {
      firstBigrams.set(bigram, count - 1);
      intersectionSize++;
    }
  }
  return (2.0 * intersectionSize) / (first.length + second.length - 2);
};

enum TvType {
  TVSERIES = "TV Series",
  MOVIE = "Movie",
  ANIME = "Anime",
}

export const findIdFromTitle = (
  title: string,
  extraData: {
    type: TvType;
    year?: number;
    totalSeasons?: number;
    totalEpisodes?: number;
    [key: string]: any;
  },
  results: any
) => {
  title = title.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();
  // Sort the retrieved info for more accurate results.
  results.sort((a: any, b: any) => {
    const targetTitle = title;
    let firstTitle: string;
    let secondTitle: string;
    if (typeof a.title == "string") firstTitle = a?.title as string;
    else firstTitle = (a?.title as string) ?? "";
    if (typeof b.title == "string") secondTitle = b.title as string;
    else secondTitle = (b?.title as string) ?? "";
    const firstRating = compareTwoStrings(targetTitle, firstTitle.toLowerCase());
    const secondRating = compareTwoStrings(targetTitle, secondTitle.toLowerCase());
    // Sort in descending order
    return secondRating - firstRating;
  });
  //remove results that dont match the type
  results = results.filter((result: any) => {
    if (extraData.type === TvType.MOVIE) return (result.type as string) === TvType.MOVIE;
    else if (extraData.type === TvType.TVSERIES) return (result.type as string) === TvType.TVSERIES;
    else return result;
  });
  // if extraData contains a year, filter out the results that don't match the year
  if (extraData && extraData.year && extraData.type === TvType.MOVIE) {
    results = results.filter((result: any) => {
      return Number(result.releaseDate) === extraData.year;
    });
  }
  // check if the result contains the total number of seasons and compare it to the extraData by 1 up or down and make sure that its a number
  if (extraData && extraData.totalSeasons && extraData.type === TvType.TVSERIES) {
    results = results.filter((result: any) => {
      const totalSeasons = (result.seasons as number) || 0;
      const extraDataSeasons = (extraData.totalSeasons as number) || 0;
      return (
        totalSeasons === extraDataSeasons ||
        totalSeasons === extraDataSeasons + 1 ||
        totalSeasons === extraDataSeasons - 1
      );
    });
  }
  return results[0];
};

export const formatTimeDuration = (totalDuration: number) => {
  const hours = Math.floor(totalDuration / 3600);
  const minutes = Math.floor(totalDuration / 60) % 60;
  const seconds = totalDuration % 60;
  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
};

export const getArrayMoviesNotSurplus = (arr: any[], perRow = 7) => {
  const moviesLength = arr.length;
  const surplus = moviesLength > perRow ? moviesLength % perRow : 0;
  return arr.slice(0, moviesLength - surplus);
};

export const getTitleFromMovieDetails = (details: TMDBType.IMovieDetails) => {
  return (
    details?.title_english ||
    details?.title_portuguese ||
    details?.title ||
    details?.original_title ||
    ""
  );
};

export const getTitleFromSeriesDetails = (details: TMDBType.ISeriesDetails) => {
  return (
    details?.title_english ||
    details?.title_portuguese ||
    details?.name ||
    details?.original_name ||
    ""
  );
};
