import { useEffect, useRef } from "react";
import { useGlobalStateContext } from "../../contexts/GlobalStateContext";
import { SettingsProvider } from "../../contexts/SettingsContext";
import { useSubtitleStateContext } from "../../contexts/SubtitleStateContext";
import { useVideoFewUpdatesContext } from "../../contexts/VideoFewUpdatesContext";
import { useVideoLotsOfUpdatesContext } from "../../contexts/VideoLotsOfUpdatesContext";
import { useVideoUpdateContext } from "../../contexts/VideoUpdateContext";
import { useEffectUpdate } from "../../hooks/useEffectUpdate";
import SettingDialog from "../settings/SettingDialog";
import ButtonOpenSettings from "../buttons/ButtonOpenSettings";

const ClickAwayListener = () => {
  const childrenRef = useRef<HTMLDivElement>(null);
  const { playerRef } = useGlobalStateContext();
  const { videoFewUpdatesContext } = useVideoFewUpdatesContext();
  const { videoLotsOfUpdatesContext, setVideoLotsOfUpdatesContext } =
    useVideoLotsOfUpdatesContext();
  const { videoUpdate } = useVideoUpdateContext();
  const { mediaState } = useSubtitleStateContext();
  useEffect(() => {
    const handler = (e: any) => {
      if (
        childrenRef.current &&
        (!childrenRef.current.contains(e.target) || e.target.className === "embed-settings-overlay")
      ) {
        setVideoLotsOfUpdatesContext((prevState) => ({
          ...prevState,
          settingsActive: false,
        }));
      }
    };
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, [setVideoLotsOfUpdatesContext]);
  useEffectUpdate(() => {
    if (!playerRef?.current) return;
    playerRef?.current?.addEventListener(
      "loadeddata",
      () => {
        if (playerRef?.current) {
          if (videoUpdate.currentTime) {
            playerRef.current.currentTime = videoUpdate.currentTime;
            playerRef.current.play();
          }
        }
      },
      { once: true }
    );
    playerRef?.current?.addEventListener("error", () => {
      playerRef?.current?.pause();
    });
  }, [mediaState.qualityIndex]);
  useEffect(() => {
    if (!playerRef?.current) return;
    localStorage.setItem("embed-speed", String(videoFewUpdatesContext.playbackSpeed));
    playerRef.current.playbackRate = videoFewUpdatesContext.playbackSpeed;
  }, [playerRef, videoFewUpdatesContext.playbackSpeed]);
  return (
    <div ref={childrenRef} style={{ position: "relative" }}>
      <ButtonOpenSettings />
      <SettingsProvider>
        <SettingDialog isShow={videoLotsOfUpdatesContext.settingsActive} />
      </SettingsProvider>
    </div>
  );
};

export default ClickAwayListener;
