import axios from "axios";
import { isJson, substringAfter, substringBefore } from "backend/movie-providers/flixhq";
import { PATH_API, USER_AGENT } from "../../constants";
import CryptoJS from "crypto-js";
import { ISource, ISubtitle } from "player";

const getEmbedKey = async (): Promise<string> => {
  let { data: key } = await axios.get(
    "https://raw.githubusercontent.com/enimax-anime/key/e4/key.txt"
  );
  if (!key) {
    const githubKey = await axios.get("https://github.com/enimax-anime/key/blob/e4/key.txt");
    key = githubKey.data;
    key = substringBefore(
      substringAfter(key, '"blob-code blob-code-inner js-file-line">'),
      "</td>"
    );
  }
  return key;
};

export interface IResExtractVidCloud {
  sources: ISource[];
  subtitles: ISubtitle[];
  thumbnail?: string;
}

const extractVidcloud = async (
  videoUrl: string,
  isAlternative: boolean = false
): Promise<IResExtractVidCloud> => {
  const host = "https://dokicloud.one";
  const host2 = "https://rabbitstream.net";
  const mediaSources: IResExtractVidCloud = { sources: [], subtitles: [], thumbnail: "" };
  try {
    const id = videoUrl.split("/").pop()?.split("?")[0];
    const options = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Referer: videoUrl,
        "User-Agent": USER_AGENT,
      },
    };
    let [{ data: ajaxSources }, decryptKey] = await Promise.all([
      axios.get(
        PATH_API.BYPASS_CORS_WORKERS(
          `${isAlternative ? host2 : host}/ajax/embed-4/getSources?id=${id}`
        ),
        options
      ),
      getEmbedKey(),
    ]);
    let encryptedURL = ajaxSources.sources;
    if (typeof decryptKey === "string") {
      JSON.parse(CryptoJS.AES.decrypt(encryptedURL, decryptKey).toString(CryptoJS.enc.Utf8));
    } else {
      const encryptedURLTemp = encryptedURL.split("");
      let newDecryptKey = "";
      // @ts-ignore
      for (const index of decryptKey) {
        for (let i = index[0]; i < index[1]; i++) {
          newDecryptKey += encryptedURLTemp[i];
          encryptedURLTemp[i] = null;
        }
      }
      decryptKey = newDecryptKey;
      encryptedURL = encryptedURLTemp.filter((x: any) => x !== null).join("");
    }
    const decryptedVal = CryptoJS.AES.decrypt(encryptedURL, decryptKey).toString(CryptoJS.enc.Utf8);
    const sources: { file: string; type: string }[] = isJson(decryptedVal)
      ? JSON.parse(decryptedVal)
      : encryptedURL;
    const requests = sources.map((source) =>
      axios.get(PATH_API.BYPASS_CORS_WORKERS(source.file), options)
    );
    const responses = await Promise.all(requests);
    const urlsAndSources = responses.reduce((acc, { data }) => {
      const lines = data.split("\n");
      const urls = lines.filter((line: any) => line.includes(".m3u8"));
      const sources = lines.filter((line: any) => line.includes("RESOLUTION="));
      const tdArray = sources.map((s: any, i: any) => {
        const f1 = s.split("x")[1];
        const f2 = urls[i];
        return [f1, f2];
      });
      return acc.concat(tdArray);
    }, []);
    mediaSources.sources = urlsAndSources.map(([f1, f2]: any) => ({ url: f2, quality: f1 }));
    mediaSources.sources.push({
      url: sources[0].file,
      quality: "Auto",
    });
    mediaSources.subtitles = ajaxSources.tracks.map((s: any) => ({
      url: s.file,
      lang: s.label ? s.label : "Default (maybe)",
      language: s.label ? s.label : "Default (maybe)",
    }));
    return mediaSources;
  } catch (err) {
    console.log("err: ", err);
    throw err;
  }
};

export default extractVidcloud;
