import React, { useRef, ButtonHTMLAttributes, memo } from "react";
import { defaultI18n } from "../../constants/i18n";
import { useGlobalStateContext } from "../../contexts/GlobalStateContext";
import IconBackward from "../../components/Icons/IconBackward";
import { useI18nContext } from "../../contexts/I18nContext";

interface ButtonBackwardMemoProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  playerRef: React.RefObject<HTMLVideoElement> | undefined;
  seekDuration: number;
}

const ButtonBackwardMemo = memo((props: ButtonBackwardMemoProps) => {
  const { i18n } = useI18nContext();
  const { playerRef, seekDuration, ...others } = props;
  const backwardRef = useRef<HTMLButtonElement>(null);
  const handleForward = () => {
    if (!playerRef || !playerRef.current) return;
    playerRef.current.currentTime = playerRef.current.currentTime - seekDuration;
  };
  return (
    <button
      ref={backwardRef}
      className="embed-center-container embed-tooltips-left embed-scale"
      data-embed-tooltips={i18n?.backward(seekDuration) || defaultI18n.backward(seekDuration)}
      onClickCapture={handleForward}
      {...others}
    >
      <IconBackward className="embed-icon-lg" />
    </button>
  );
});

ButtonBackwardMemo.displayName = "ButtonBackwardMemo";

const ButtonBackward = () => {
  const { playerRef, seekDuration } = useGlobalStateContext();
  return <ButtonBackwardMemo playerRef={playerRef} seekDuration={seekDuration || 10} />;
};

export default ButtonBackward;
